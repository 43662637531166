import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { baseUrl } from "../components/BaseUrl";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import Swal from "sweetalert2";
import Header from "../components/Header";
import moment from 'moment';
import profileImage from '../assets/images/profile.png';
import NumberFormat from 'react-number-format';

class RepaidLoans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      repaidData: [],
      userData:[],
      isLoading: false,
      loadRepaid: false,
      isRepaidLoading: false,
    };
  }


  getUserDetails = (userId) => {
    this.setState({ isRepaidLoading: true })
    // console.warn(userId);
    const url = `${baseUrl}Loans/userid/${userId.trim()}`;
    this.setState({isLoading: true});
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    })
      .then(res => res.json())
      .then(res => {
        // console.warn(res);
        this.setState({
          isRepaidLoading: false,
          userData: [res],
        });
        // console.warn(this.state.userData);
      })
      .catch(error => {
        this.setState({error: true, isRepaidLoading: false});
        alert(error);
      });
  }

  goRepaid = async () => {
    if (localStorage.getItem("login") === "") {
      this.props.history.push("/login");
    }
    $(document).ready(function() {
      setTimeout(function() {
        $("#repaid").DataTable({
          pagingType: "full_numbers",
          pageLength: 20,
          processing: true,
          dom: "Bfrtip",
          select: {
            style: "single",
          },

          lengthChange: false,

          buttons: [
            {
              extend: "pageLength",
              className: "btn btn-success bg-success",
            },
            {
              extend: "copy",
              className: "btn btn-success bg-success",
            },
            {
              extend: "csv",
              className: "btn btn-primary bg-primary",
            },
            {
              extend: "print",
              customize: function(win) {
                $(win.document.body).css("font-size", "10pt");
                $(win.document.body)
                  .find("table")
                  .addClass("compact")
                  .css("font-size", "inherit");
              },
              className: "btn btn-danger bg-danger",
            },
          ],

          fnRowCallback: function(
            nRow,
            aData,
            iDisplayIndex,
            iDisplayIndexFull
          ) {
            var index = iDisplayIndexFull + 1;
            $("td:first", nRow).html(index);
            return nRow;
          },

          lengthMenu: [
            [10, 20, 30, 50, -1],
            [10, 20, 30, 50, "All"],
          ],
          columnDefs: [
            {
              targets: 0,
              render: function(data, type, row, meta) {
                // console.log(meta.row);
                // console.log(type === "export" ? meta.row : data);
                return type === "export" ? meta.row + 1 : data;
              },
            },
          ],
        });
      }, 1000);
    });
    this.setState({ loadRepaid: true });
    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    };
    await fetch(`${baseUrl}LoansMgt/GetLoansByStatus/repaid`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        console.warn(responseJson);
        try {
          this.setState({
            loadRepaid: false,
            repaidData: responseJson,
          });
        } catch (error) {
          Swal.fire({
            title: "Session expired",
            text: "Login",
            type: "error",
          }).then(() => {
            this.props.history.push("/login");
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  showRepaid = () => {
    return this.state.repaidData.length > 0 && this.state.repaidData.map((item, index) => {
      return (
        <tr>
          <td className="text-xs font-weight-bold">{index+1}</td>
          <td className="text-xs font-weight-bold">{`${item.surname} ${item.firstName} ${item.otherNames}`}</td>
          <td className="text-xs font-weight-bold">
          &#8358;
          <NumberFormat
          value={item.principalAmount}
          displayType={'text'}
          thousandSeparator={true}
          renderText={value => <span> {value}</span>}
          /></td>
          <td className="text-xs font-weight-bold">
          &#8358;
          <NumberFormat
          value={item.repaymentamount}
          displayType={'text'}
          thousandSeparator={true}
          renderText={value => <span> {value}</span>}
          /></td>
          <td><button className={item.status === "repaid" ? "btn btn-success text-light text-xs font-weight-bold" : ""}>{item.status}</button></td>
         {/*  <td className="text-xs font-weight-bold">{moment(item.dateApplied).format('LL')}</td>
          <td className="text-xs font-weight-bold">{moment(item.dateApproved).format('LL')}</td> */}
          <td className="text-xs font-weight-bold">{moment(item.dateRepaid).format('LL')}</td>
           <td>
           <button id="#dropdownMenuButton2" className="btn btn-primary-2 mb-0" data-bs-toggle="dropdown" aria-expanded="false"><span className="iconify" data-icon="charm:menu-meatball" style={{fontSize: 'large'}} ></span></button>
           <ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="#dropdownMenuButton2">
           <li className="mb-2" id = { item.userId } onClick={() => this.getUserDetails(item.userId)} data-bs-toggle="modal" data-bs-target="#viewRepaid">
             <a className="dropdown-item border-radius-md" href="javascript:;">
               <div className="d-flex py-1">
                   <h6 className="text-sm font-weight-normal mb-1">
                     <span className="font-weight-bold" >View</span>
                   </h6>
               </div>
             </a>
           </li>

                   {/*
                   <li className="mb-2">
                     <a className="dropdown-item border-radius-md" href="javascript:;">
                       <div className="d-flex py-1">
                           <h6 className="text-sm font-weight-normal mb-1">
                             <span id = { item.userid } onClick={() => this.updateUserDetails(item.userid)} className="font-weight-bold" data-bs-toggle="modal" data-bs-target="#exampleModal2">Approve</span>
                           </h6>
                       </div>
                     </a>
                   </li>
                   */}
                 </ul>
                 </td>

        </tr>
      );
    });
  };

  componentDidMount() {
    this.goRepaid();
  }

  render() {
    const { isRepaidLoading, userData } = this.state;
    return (
      <div>
        <Header />
        <div className="container d-flex justify-content-end" style={{marginTop: 45, marginBottom:250, marginLeft: -45}}>
          {this.state.loadRepaid ? (
            <Spinner animation="border" variant="danger" />
          ) : (
            <div className="container-flex d-flex justify-content-end table-responsive" style={{ marginLeft: 0 }}>
            <div className="mb-4"></div>
              <div className="table-responsive" style={{ marginTop: 18 }}>
                <table
                  id="repaid"
                  className="table align-items-center justify-content-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">S/N</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Name</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Principal Amount</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Repayment Amount</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Status</th>
                      {/*<th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Date Applied</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Date Approved</th> */}
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Date Repaid</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.showRepaid()}</tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        {/* View Modal */}
        <div className="modal fade" id="viewRepaid" tabindex="-1" aria-labelledby="viewRepaid" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header d-flex align-items-center justify-content-between">
                <h5 className="modal-title">Details</h5>
                <button type="button" className="btn btn-link m-0 p-0 text-dark fs-4" data-bs-dismiss="modal" aria-label="Close"><span class="iconify" data-icon="carbon:close"></span></button>
              </div>
                { isRepaidLoading ? <Spinner animation="border" style={{ position:'relative', right:0, left: 220, top: 0 }} className="text-center" variant="danger" size="sm" />  :
              <div Name="modal-body" style={{ padding: 18}}>
              { userData.length > 0 && userData.map((item) => {
                return (
                <div className="row">
                  <div className="d-flex px-3">
                    <div className="my-auto">
                      <img src={profileImage} class="avatar avatar-exbg  me-4 " />
                    </div>
                    <div className="d-flex flex-column justify-content-center" style={{ padding: 9}}>
                      <h6 className="text-lg font-weight-normal mb-1">
                        <span className="font-weight-bold">{`${item.surname} ${item.firstName} ${item.otherNames}`}</span>
                      </h6>
                        <span className="mbName-2 text-xs font-weight-bold">USER ID: <span className="text-danger text-sm font-weight-bold">{item.userId}</span></span>
                        <span className="mb-2 text-xs font-weight-bold">APPLICATION ID: <span className="text-danger text-sm font-weight-bold">{item.applicationId}</span></span>
                      <span className="mb-2 text-xs font-weight-bold">Status: <span><button className={item.status === "approved" ? "btn btn-success badge badge-success text-xs font-weight-bold" : item.status === "pending" ? "btn btn-warning badge badge-warning text-xs font-weight-bold" : "btn btn-danger badge badge-danger text-xs font-weight-bold"}>{item.status}</button></span></span>
                    </div>
                  </div>
                  <span className="pt-1"><hr className="dark horizontal my-3" /></span>
                  <div className="d-flex flex-column px-3">
                    <h6 className="mb-3 text-sm">Loan History</h6>
                    <span className="mb-2 text-xs">Date Applied: <span className="text-dark font-weight-bold ms-sm-2">{item.dateApplied === "Invalid date" ? null : moment(item.dateApplied).format("LL")}</span></span>
                    <span className="mb-2 text-xs">Date Approved: <span className="text-dark font-weight-bold ms-sm-2">{item.dateApproved === "Invalid date" ? null : moment(item.dateApproved).format("LL")}</span></span>
                    <span className="mb-2 text-xs">Date Repaid: <span className="text-dark font-weight-bold ms-sm-2">{item.dateRepaid === "Invalid date" ? null : moment(item.dateRepaid).format("LL")}</span></span>
                    <span className="mb-2 text-xs">Date Updated: <span className="text-dark font-weight-bold ms-sm-2">{item.dateupdated === "Invalid date" ? null : moment(item.dateupdated).format("LL")}</span></span>
                    <span className="mb-2 text-xs">Interest Rate: <span className="text-success ms-sm-2 font-weight-bold">{item.interestRate}</span></span>
                    <span className="mb-2 text-xs">Loan Code: <span className="text-success ms-sm-2 font-weight-bold">{item.loancode}</span></span>
                    <span className="mb-2 text-xs">Purpose: <span className="text-dark ms-sm-2 font-weight-bold">{item.purpose}</span></span>
                    <span className="mb-2 text-xs">Principal Amount: <span className="text-dark ms-sm-2 font-weight-bold">  &#8358;
                      <NumberFormat
                      value={item.principalAmount}
                      displayType={'text'}
                      thousandSeparator={true}
                      renderText={value => <span> {value}</span>}
                      />
                      </span></span>
                    <span className="mb-2 text-xs">Repayment Amount: <span className="text-dark ms-sm-2 font-weight-bold">&#8358;
                      <NumberFormat
                      value={item.repaymentamount}
                      displayType={'text'}
                      thousandSeparator={true}
                      renderText={value => <span> {value}</span>}
                      />
                      </span></span>
                  </div>
                </div>
              )})}
              </div>
              }
              <div className="modal-footer">
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default RepaidLoans;
