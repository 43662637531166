import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { baseUrl } from "../components/BaseUrl";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import Swal from "sweetalert2";
import Header from "../components/Header";
import moment from 'moment';
import NumberFormat from 'react-number-format';

class SuspendedLonas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suspendedData: [],
      loadSuspended: false,
    };
  }

  goSuspended = async () => {
    $(document).ready(function() {
      setTimeout(function() {
        $("#suspended").DataTable({
          pagingType: "full_numbers",
          pageLength: 20,
          processing: true,
          dom: "Bfrtip",
          select: {
            style: "single",
          },

          lengthChange: false,

          buttons: [
            {
              extend: "pageLength",
              className: "btn btn-success bg-success",
            },
            {
              extend: "copy",
              className: "btn btn-success bg-success",
            },
            {
              extend: "csv",
              className: "btn btn-primary bg-primary",
            },
            {
              extend: "print",
              customize: function(win) {
                $(win.document.body).css("font-size", "10pt");
                $(win.document.body)
                  .find("table")
                  .addClass("compact")
                  .css("font-size", "inherit");
              },
              className: "btn btn-danger bg-danger",
            },
          ],

          fnRowCallback: function(
            nRow,
            aData,
            iDisplayIndex,
            iDisplayIndexFull
          ) {
            var index = iDisplayIndexFull + 1;
            $("td:first", nRow).html(index);
            return nRow;
          },

          lengthMenu: [
            [10, 20, 30, 50, -1],
            [10, 20, 30, 50, "All"],
          ],
          columnDefs: [
            {
              targets: 0,
              render: function(data, type, row, meta) {
                // console.log(meta.row);
                // console.log(type === "export" ? meta.row : data);
                return type === "export" ? meta.row + 1 : data;
              },
            },
          ],
        });
      }, 1000);
    });
    this.setState({ loadSuspended: true });
    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    };
    await fetch(`${baseUrl}LoansMgt/GetLoansByStatus/suspended`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        try {
          this.setState({
            loadSuspended: false,
            suspendedData: responseJson,
          });
        } catch (error) {
          Swal.fire({
            title: "Session expired",
            text: "Login",
            type: "error",
          }).then(() => {
            this.props.history.push("/login");
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  showSuspended = () => {
    return this.state.suspendedData.map((item, index) => {
      return (
        <tr>
          <td className="text-xs font-weight-bold">{index+1}</td>
          <td className="text-xs font-weight-bold">{`${item.surname} ${item.firstName} ${item.otherNames}`}</td>
          <td className="text-xs font-weight-bold">
          &#8358;
          <NumberFormat
          value={item.principalAmount}
          displayType={'text'}
          thousandSeparator={true}
          renderText={value => <span> {value}</span>}
          /></td>
          <td className="text-xs font-weight-bold">
          &#8358;
          <NumberFormat
          value={item.repaymentamount}
          displayType={'text'}
          thousandSeparator={true}
          renderText={value => <span> {value}</span>}
          /></td>
          <td><button className={item.status === "repaid" ? "btn btn-success text-light text-xs font-weight-bold" : item.status="pending" ? "btn btn-warning text-light text-xs font-weight-bold" : item.status === "suspended" ? "btn btn-danger text-light text-xs font-weight-bold" : "text-xs font-weight-bold"}>{item.status}</button></td>
          <td className="text-xs font-weight-bold">{moment(item.dateApplied).format('LL')}</td>
          <td className="text-xs font-weight-bold">{moment(item.dateApproved).format('LL')}</td>
          <td className="text-xs font-weight-bold">{moment(item.dateRepaid).format('LL')}</td>
          <td>
                 <button className="btn btn-primary-2 mb-0" id="viewRepaid" data-bs-toggle="dropdown" aria-expanded="false"><span class="iconify" data-icon="charm:menu-meatball" style={{fontSize: 'large'}} ></span></button>
                 <ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="#dropdownMenuButton2">
                   <li className="mb-2">
                     <a className="dropdown-item border-radius-md" href="javascript:;">
                       <div className="d-flex py-1">
                           <h6 className="text-sm font-weight-normal mb-1">
                             <span id = { item.userid } onClick={() => this.getUserDetails(item.userid)} className="font-weight-bold" data-bs-toggle="modal" data-bs-target="#exampleModal1">View</span>
                           </h6>
                       </div>
                     </a>
                   </li>
                   <li className="mb-2">
                     <a className="dropdown-item border-radius-md" href="javascript:;">
                       <div className="d-flex py-1">
                           <h6 className="text-sm font-weight-normal mb-1">
                             <span id = { item.userid } onClick={() => this.updateUserDetails(item.userid)} className="font-weight-bold" data-bs-toggle="modal" data-bs-target="#exampleModal2">Approve</span>
                           </h6>
                       </div>
                     </a>
                   </li>
                 </ul>
                 </td>
        </tr>
      );
    });
  };

  componentDidMount() {
    this.goSuspended();
  }

  render() {
    return (
      <div>
        <Header />
        <div
          className="container d-flex justify-content-end"
          style={{ marginTop: 45 }}
        >
          {this.state.loadSuspended ? (
            <Spinner animation="border" variant="danger" />
          ) : (
            <div className="container-flex d-flex justify-content-end table-responsive" style={{padding: 18, marginLeft: 63 }}>
              <h3 className="text-center"> </h3>
              <div className="table-responsive" style={{ marginTop: 18 }}>
                <table
                  id="suspended"
                  className="table align-items-center justify-content-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">S/N</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Surname</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Principal Amount</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Repayment Amount</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Status</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Date Applied</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Date Approved</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Date Repaid</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.showSuspended()}</tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SuspendedLonas;
