import React, { Component } from "react";
import Swal from 'sweetalert2';
import { Spinner, Table, } from 'react-bootstrap';
import { baseUrl } from "../components/BaseUrl";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
import Header from "../components/Header";
import Footer from "../components/Footer";
import OfferOptions from "../components/OfferOptions";



class LoanAdmin extends Component {

    constructor(props){
        super(props);
        this.state = {
            loadOffers: false,
            searchOffers: false,
            dataOffers: [],
            showOffersTable: false,
            stageId: '',
            loanId: '',
            stageName: '',
            principal: '',
            penalty: '',
            interest: '',
            tenure: ''
        }
        this.onInputchange = this.onInputchange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onInputchange(event) {
          this.setState({
            stageId: event.target.value,
            loanId: event.target.value,
            stageName: event.target.value,
            principal: event.target.value,
            penalty: event.target.value,
            interest: event.target.value,
            tenure: event.target.value,
          });
        }

        async handleSubmit(e) {
          e.preventDefault()
          alert(this.state.value)
          // if (this.state.selectValue === "" || this.state.selectValue === "Choose") {
          //   alert("You have not made any selection. Choose enable or disable");
          //   e.preventDefault();
          // } else {
          //        if (this.state.selectValue.startsWith("diable")) {
          //           Swal.fire({
          //             title: "One moment ...",
          //             text: "Getting things ready",
          //           });
          //     let str = this.state.selectValue.substring(6);
              e.preventDefault();
              var obj = {
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("login"),
                },
                body: JSON.stringify({
                  interest: this.state.interest,
                  loanId: this.state.loanId,
                  stageId: this.state.stageId,
                  stageName: this.state.stageName,
                  principal: this.state.principal,
                  penalty: this.state.penalty

                }),
              };
              await fetch(`${baseUrl}offers/updateOffer`, obj)
                .then((response) => response.json())
                .then((responseJson) => {
                  Swal.close()
                  if(window.location.reload()){
                    Swal.fire({
                      title: "Success",
                      text: " has been successfully disabled",
                      icon: "success",
                      confirmButtonText: "OK",
                    });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    title: "Error!",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                });
            // } else if (this.state.selectValue.startsWith("enable")) {
            //   Swal.fire({
            //     title: "One moment ...",
            //     text: "Getting things ready",
            //   });
            //   let str = this.state.selectValue.substring(6);
            //   e.preventDefault();
            //   let obj = {
            //     method: "PUT",
            //     headers: {
            //       Accept: "application/json",
            //       "Content-Type": "application/json",
            //       Authorization: "Bearer " + localStorage.getItem("login"),
            //     },
            //     body: JSON.stringify({
            //       status: "enabled",
            //       userid: str.trim(),
            //     }),
            //   };
            //   await fetch(`${baseUrl}UsersMgt/UpdateUserStatus`, obj)
            //     .then((response) => response.json())
            //     .then((responseJson) => {
            //       if(window.location.reload()){
            //         Swal.fire({
            //           title: "Success",
            //           text: str.trim()+ " has been successfully enabled.",
            //           icon: "success",
            //           confirmButtonText: "OK",
            //         });
            //       }
            //     })
            //     .catch((error) => {
            //       Swal.fire({
            //         title: "Error!",
            //         text: error.message,
            //         icon: "error",
            //         confirmButtonText: "OK",
            //       });
            //     });
            // }
          }



    getOffers = async () => {
        if(localStorage.getItem('login') === ''){
            this.props.history.push("/login")
          }
        this.setState({loadOffers:true})
            let obj = {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+localStorage.getItem('login')
            },
            }
      await fetch('https://rubimoni.com:8443/Offers/getAllOffers', obj)
      .then(response => response.json())
      .then(responseJson => {
        // console.warn(responseJson)
        try{
       this.setState({
         loadOffers: false,
         showOffersTable: true,
         dataOffers:responseJson,
        })
      }catch(e){
        alert(e)
      }
     })
     .catch(error => {
        Swal.fire({
            title: 'Error!',
            text: error.message,
            icon: 'error',
            confirmButtonText: 'OK'
          })
     });
      }


      showOffersTable = () => {
        try{
          // alert("hello")
        $(document).ready(function () {
        $('#offers').DataTable();
        });
        return this.state.dataOffers.map(item => {
          return(
            <tr>
            <td>#</td>
            <td>
               {item.LoanId}
            </td>
            <td>
              {item.stageId}
            </td>
            <td>
              {item.stageName}
            </td>
            <td>
               {item.principal}
            </td>
            <td>
               {item.penalty}
            </td>
            <td>
               {item.interest}
           </td>
            <td>
               {item.Tenure}
              </td>

            </tr>

          );
        });
      }catch(e){
        // Swal.fire({
        //   title: "Session expired",
        //   text: "Login",
        //   type: "error",
        // }).then(function() {
        //   window.location.href = "/login";
        // });
      }
    }



      componentDidMount(){
          this.getOffers()
      }


    render(){
        return(
            <div>
                <Header />

                <OfferOptions />

              <div className="d-flex justify-content-center container" style={{marginTop:45}}>
              {this.state.loadOffers ?  (
            <Spinner animation="border" variant="dark" />
          ) :
              <div className="table-responsive">
              <form  onSubmit={this.handleSubmit}>
              <Table id="offers" className="table display table-bordered table-condensed table-striped table-responsive" cellspacing="0" width="100%">

              <thead>
              <tr>
              <th>#</th>
              <th>Loan ID</th>
              <th>Stage ID</th>
              <th>Stage Name</th>
              <th>Principal</th>
              <th>Penalty</th>
              <th>Interest</th>
              <th>Tenure</th>
              </tr>
              </thead>
              <tbody>
              {this.showOffersTable()}
              </tbody>
              </Table>
              </form>
              </div>
               }
              </div>


                <Footer />
            </div>
        )
    }
}

export default LoanAdmin
