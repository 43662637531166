import React, { Component } from "react";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import { baseUrl } from "../components/BaseUrl";
import Header from "../components/Header";

class UpdateLedger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isPartialDebiting: false
    };
  }

  updateRunningLoan = async () => {
    this.setState({ loading: true })
    if (localStorage.getItem("login") === "") {
      this.props.history.push("/login");
    }
    var obj = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    };
    await fetch(`${baseUrl}LoanAdmin/updateRunningLoans`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        console.warn(responseJson);
        if (responseJson.message === "All Approved Loans updated") {
          this.setState({ loading: false })
          Swal.fire({
            title: "Success",
            text: responseJson.message,
            icon: "success",
            confirmButtonText: "OK",
          });
        } else {
          this.setState({ loading: false })
          Swal.fire({
            title: "Error!",
            text: responseJson.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  partialDebit = async () => {
    this.setState({ isPartialDebiting: true })
    var obj = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    };
    await fetch(`${baseUrl}CardsDebit/cardDebit`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        console.warn(responseJson);
        if (
          responseJson.message ==
          "Loans Auto Debit Successful"
        ) {
          this.setState({isPartialDebiting: false})
          Swal.fire({
            title: "Success",
            text: responseJson.message,
            icon: "success",
            confirmButtonText: "OK",
          });
          // window.location.href = "/dashboard";
        } else {
          Swal.fire({
            title: "Error!",
            text: responseJson.message,
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({isPartialDebiting: false})
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({isPartialDebiting: false})
      });
  };

  render() {
    const { loading, isPartialDebiting } = this.state;
    return (
      <div>
        <Header />
        <br /><br/><br />
        <h5 className="text-center">Click here to update the Loan Ledger / Partial Debit</h5><br />
        <div className="container d-flex justify-content-center">
        <button className="btn btn-success btn-lg" onClick={()=> this.updateRunningLoan()}>
        {loading ? (
          <Spinner animation="border" variant="light" />
        ) : 'Update Loan Ledger'}
        </button>
        <span style={{ width: 45 }}></span>
        <button className="btn btn-success btn-lg" onClick={()=> this.partialDebit()}>
        {isPartialDebiting ? (
          <Spinner animation="border" variant="light" />
        ) : 'Perform Auto Debit'}
        </button>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 350,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
        </div>
      </div>
    );
  }
}

export default UpdateLedger;
