import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { baseUrl } from "../components/BaseUrl";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import Swal from "sweetalert2";
import Header from "../components/Header";
import UserOptions from "../components/UserOptions";
import moment from 'moment';
import profileImage from '../assets/images/profile.png';
import logo from '../assets/images/logo.png';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadAll: false,
      isLoading: false,
      selectValue: "",
      isEnabledLoading: false,
      isDisabledLoading: false,
      enabledUserData: [],
      disabledUserData: [],
      userData: [],
      allData: [],
      postsPerPage: 10,
      currentPage: 1,
    };
  }

  checkLocalStorage() {
    if (localStorage.getItem("login") === "") {
      this.props.history.push("/login");
    }
  }

  getUserDetails = (userId) => {
    const url = `${baseUrl}Users/id/${userId}`;
    this.setState({isLoading: true});
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
          isLoading: false,
          userData: [res],
        });
      })
      .catch(error => {
        this.setState({error: true, loading: false});
        alert(error);
      });
  }

  getEnabledUserDetails = (userId) => {
    const url = `${baseUrl}Users/id/${userId}`;
    this.setState({isLoading: true});
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    })
      .then(res => res.json())
      .then(res => {
        // console.warn(res);
        this.setState({
          isLoading: false,
          enabledUserData: [res],
        });
      })
      .catch(error => {
        this.setState({error: true, loading: false});
        alert(error);
      });
  }

  getDisabledUserDetails = (userId) => {
    const url = `${baseUrl}Users/id/${userId}`;
    this.setState({isLoading: true});
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    })
      .then(res => res.json())
      .then(res => {
        // console.warn(res);
        this.setState({
          isLoading: false,
          disabledUserData: [res],
        });
      })
      .catch(error => {
        this.setState({error: true, loading: false});
        alert(error);
      });
  }

  enableUserStatus = async (userId) => {
    this.setState({ isEnabledLoading: true })
    var obj = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
      body: JSON.stringify({
        status: "enabled",
        userid: `${userId}`,
      }),
    };
    await fetch(`${baseUrl}UsersMgt/UpdateUserStatus`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.warn(responseJson);
        if(responseJson.message === "User Status Updated"){
          Swal.fire({
              title: "Success",
              text: `${userId} status has been successfully enabled`,
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              window.location.reload();
            })
            this.setState({ isEnabledLoading: false })
        }else{
          Swal.fire({
              title: "Error",
              text: `We could not complete that. Please try again later`,
              icon: "error",
              confirmButtonText: "OK",
            });
            this.setState({ isEnabledLoading: false })
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
      this.setState({ isEnabledLoading: false })
  }

  disableUserStatus = async (userId) => {
    this.setState({ isDisabledLoading: true })
    var obj = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
      body: JSON.stringify({
        status: "disabled",
        userid: `${userId}`,
      }),
    };
    await fetch(`${baseUrl}UsersMgt/UpdateUserStatus`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.warn(responseJson);
        if(responseJson.message === "User Status Updated"){
          Swal.fire({
              title: "Success",
              text: `${userId} status has been successfully disabled`,
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
               window.location.reload();
          });
            this.setState({ isDisabledLoading: false })
        }else{
          Swal.fire({
              title: "Error",
              text: `We could not complete that. Please try again later`,
              icon: "error",
              confirmButtonText: "OK",
            });
            this.setState({ isDisabledLoading: false })
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ isDisabledLoading: false })
      });
  }

  showAll = async () => {
    if (localStorage.getItem("login") === "") {
      this.props.history.push("/login");
    }
    this.setState({ loadAll: true });
    $(document).ready(function() {
      setTimeout(function() {
        $("#allTable").DataTable({
          pagingType: "full_numbers",
          pageLength: 20,
          dom: "Bfrtip",
          select: {
            style: "single",
          },

          buttons: [
            {
              extend: "pageLength",
              className: "btn btn-success bg-success",
            },
            {
              extend: "copy",
              className: "btn btn-success bg-success",
            },
            {
              extend: "csv",
              className: "btn btn-primary bg-primary",
            },
            {
              extend: "print",
              customize: function(win) {
                $(win.document.body).css("font-size", "10pt");
                $(win.document.body)
                  .find("table")
                  .addClass("compact")
                  .css("font-size", "inherit");
              },
              className: "btn btn-danger bg-danger",
            },
          ],

          fnRowCallback: function(
            nRow,
            aData,
            iDisplayIndex,
            iDisplayIndexFull
          ) {
            var index = iDisplayIndexFull + 1;
            $("td:first", nRow).html(index);
            return nRow;
          },

          lengthMenu: [
            [10, 20, 30, 50, -1],
            [10, 20, 30, 50, "All"],
          ],
        });
      }, 1000);
    });
    var obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    };
    await fetch(`${baseUrl}UsersMgt/GetUsersByType`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        console.warn(responseJson);
        try {
          if(responseJson.error === "Unauthorized"){
            Swal.fire({
              title: "Session expired",
              text: "Login",
              type: "error",
            }).then(() => {
              this.props.history.push("/login");
            });
          }
          else {
            this.setState({
            loadAll: false,
            loadPrivate: false,
            allData: responseJson,
          });
        }
        } catch (e) {
          Swal.fire({
            title: "Session expired",
            text: "Login",
            type: "error",
          }).then(() => {
            this.props.history.push("/login");
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  showPagination = () => {
    const { postsPerPage, allData } = this.state;
    const pageNumbers = [];
    const totalPosts = allData.length;
    for(let i = 1; i<= Math.ceil(totalPosts/postsPerPage); i++){
      pageNumbers.push(i)
    }

   const paginate = (pageNumbers) => {
     this.setState({currentPage: pageNumbers})
   }

    return(
      <nav>
      <ul className="pagination">
      {pageNumbers.map(number => (
        <li key={number} className={this.state.currentPage === number ? 'page-item active' : 'page-item'}>
        <button onClick={()=> paginate(number)} className="page-link">
          { number }
        </button>
       </li>
     ))}
      </ul>
      </nav>
    )
  }

  showAllTable = () => {
    const { postsPerPage, currentPage, allData } = this.state;
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = allData.length > 0 ? allData.slice(indexOfFirstPost, indexOfLastPost) : null;
    try {
      return currentPosts.map((item, index) => {
        return (
          <tr>
            <td key={item.userId} className="text-xs font-weight-bold">{index+1}</td>
            <td className="text-xs font-weight-bold">{item.usertype}</td>
            <td><button className={item.active == "1" ? "btn btn-success text-light text-xs font-weight-bold" : "btn btn-danger text-light text-xs font-weight-bold"}>{item.active == "1" ? "true" : "false"}</button></td>
            <td className="text-xs font-weight-bold">{`${item.surname} ${item.firstName} ${item.otherNames}`}</td>
            <td className="text-xs font-weight-bold">{item.gender}</td>
            <td className="text-xs font-weight-bold">{moment(item.DOB).format("LL") === "Invalid date" ? null : moment(item.DOB).format("LL") }</td>
            <td className="text-xs font-weight-bold">{item.email}</td>
            <td><button className={item.status === "enabled" ? "btn btn-success text-light text-xs font-weight-bold" : item.status === "disabled" ? "btn btn-danger text-light text-xs font-weight-bold" :"text-xs font-weight-bold"}>{item.status}</button></td>
            <td>
             <button className="btn btn-primary-2 mb-0" id="viewRepaid" data-bs-toggle="dropdown" aria-expanded="false"><span class="iconify" data-icon="charm:menu-meatball" style={{fontSize: 'large'}} ></span></button>
             <ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="#dropdownMenuButton2">
               <li className="mb-2" id = { item.userId } onClick={() => this.getUserDetails(item.userId)} data-bs-toggle="modal" data-bs-target="#viewUsers">
                 <a className="dropdown-item border-radius-md" href="javascript:;">
                   <div className="d-flex py-1">
                       <h6 className="text-sm font-weight-normal mb-1">
                         <span className="font-weight-bold">View</span>
                       </h6>
                   </div>
                 </a>
               </li>
               <li className="mb-2" id = { item.userId } onClick={() => this.getEnabledUserDetails(item.userId)} data-bs-toggle="modal" data-bs-target="#enableModal">
                 <a className="dropdown-item border-radius-md" href="javascript:;">
                   <div className="d-flex py-1">
                       <h6 className="text-sm font-weight-normal mb-1 text-success">
                         <span className="font-weight-bold">Enable</span>
                       </h6>
                   </div>
                 </a>
               </li>
               <li className="mb-2" id = { item.userId } onClick={() => this.getDisabledUserDetails(item.userId)} data-bs-toggle="modal" data-bs-target="#disableModal">
                 <a className="dropdown-item border-radius-md" href="javascript:;">
                   <div className="d-flex py-1">
                       <h6 className="text-sm font-weight-normal mb-1 text-danger">
                         <span className="font-weight-bold">Disable</span>
                       </h6>
                   </div>
                 </a>
               </li>
             </ul>
             </td>
          </tr>
        );
      });
    } catch (e) {
      // Swal.fire({
      //   title: "Session expired",
      //   text: "Login",
      //   type: "error",
      // }).then(function() {
      //   window.location.href = "/login";
      // });
    }
  };

  componentDidMount() {
    this.showAll();
    this.checkLocalStorage();
  }

  render() {
    const { isLoading, isEnabledLoading, isDisabledLoading, enabledUserData, disabledUserData, userData } = this.state;
    return (
      <div>
        <Header />
        <div
          className="container d-flex justify-content-center"
          style={{ marginBottom: 80 }}
        >
          {this.state.loadAll ? (
            <Spinner animation="border" variant="danger" />
          ) : (
            <div className="container-flex justify-content-end table-responsive" style={{padding: 18, marginLeft: 63 }}>
              <div className="container table-responsive">
                <table
                  //id="allTable"
                  className="table">
                  <thead>
                    <tr>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">S/N</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">User type</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Active</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Name</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Gender</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">DOB</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Email</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Status</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.showAllTable()}</tbody>
                </table>
              </div>
              <div style={{float: 'right'}}>
              {this.showPagination()}
              </div>
            </div>
          )}
        </div>
        <div>
        </div>
        {/* Start of View Modal */}
        <div className="modal fade" id="viewUsers" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header d-flex align-items-center justify-content-between">
                <h5 className="modal-title">Details</h5>
                <button type="button" className="btn btn-link m-0 p-0 text-dark fs-4" data-bs-dismiss="modal" aria-label="Close"><span class="iconify" data-icon="carbon:close"></span></button>
              </div>
                { isLoading ? <Spinner animation="border" style={{ position:'relative', right:0, left: 220, top: 0 }} className="text-center" variant="danger" size="sm" />  :
              <div class="modal-body">
              { userData.length > 0 && userData.map((item) => {
                return (
                <div className="row">
                  <div className="d-flex px-3">
                    <div className="my-auto">
                      <img src={profileImage} className="avatar avatar-exbg  me-4 " />
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="text-lg font-weight-normal mb-1">
                        <span className="font-weight-bold">{`${item.surname} ${item.firstName} ${item.otherNames}`}</span>
                      </h6>
                        <span className="mb-2 text-sm font-weight-bold">USERID: <span className="text-danger text-sm font-weight-bold">{item.userId}</span></span>
                      <span className="mb-2 text-sm font-weight-bold">Status: <span><button className={item.status === "enabled" ? "btn btn-success badge badge-success text-xs font-weight-bold" : "btn btn-danger badge badge-success text-xs font-weight-bold"}>{item.status}</button></span></span>
                    </div>
                  </div>
                  <span className="pt-1"><hr className="dark horizontal my-3" /></span>
                  <div className="d-flex flex-column px-3">
                    <h6 className="mb-3 text-sm">User History</h6>
                    <span className="mb-2 text-xs">Date of Birth: <span className="text-dark font-weight-bold ms-sm-2">{item.dateOfBirth === "Invalid date" ? null : moment(item.dateOfBirth).format("LL")}</span></span>
                    <span className="mb-2 text-xs">Stage Id: <span className="text-success ms-sm-2 font-weight-bold">{item.stageId}</span></span>
                    <span className="mb-2 text-xs">Address: <span className="text-dark ms-sm-2 font-weight-bold">{item.address}</span></span>
                    <span className="mb-2 text-xs">City: <span className="text-dark ms-sm-2 font-weight-bold">{item.city}</span></span>
                    <span className="mb-2 text-xs">Education: <span className="text-dark ms-sm-2 font-weight-bold">{item.education}</span></span>
                    <span className="mb-2 text-xs">Email: <span className="text-dark ms-sm-2 font-weight-bold">{item.email}</span></span>
                    <span className="mb-2 text-xs">Email Verified: <span className={item.EmailVerified === "Verified" ? "text-xs text-success font-weight-bold" : "text-xs text-danger font-weight-bold"}>{item.EmailVerified}</span></span>
                    <span className="mb-2 text-xs">State: <span className="text-dark ms-sm-2 font-weight-bold">{item.state}</span></span>
                    <span className="mb-2 text-xs">Country Code: <span class="text-dark ms-sm-2 font-weight-bold">{item.countryid}</span></span>
                    <span className="mb-2 text-xs">Phone: <span className="text-dark ms-sm-2 font-weight-bold">{item.telephone}</span></span>
                    <span className="mb-2 text-xs">Phone Verified: <span className={item.TelephoneVerified === "Verified" ? "text-xs text-success font-weight-bold" : "text-xs text-danger font-weight-bold"}>{item.TelephoneVerified}</span></span>
                  </div>
                </div>
              )})}
              </div>
              }
              <div className="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* End of View Modal */}

        {/* Start of Enable Modal  */}
        <div className="modal fade" id="enableModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header d-flex align-items-center justify-content-between">
                <h5 className="modal-title">Enable User</h5>
                <button type="button" className="btn btn-link m-0 p-0 text-dark fs-4" data-bs-dismiss="modal" aria-label="Close"><span className="iconify" data-icon="carbon:close"></span></button>
              </div>
              { isLoading ? <Spinner animation="border" style={{ position:'relative', right:0, left: 220, top: 0, padding: 10 }} className="text-center" variant="danger" size="sm" />  :
              <div className="modal-body">
                <div className="row">
                  <div className="d-flex px-3">
                    <div className="my-auto">
                      <img src={logo} className="avatar avatar-exbg  me-4 " />
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="text-lg font-weight-normal mb-1">
                        <span className="font-weight-bold">Rubi Moni</span>
                      </h6>
                    </div>
                  </div>
                  <span className="pt-3"><hr class="dark horizontal my-3" /></span>
                  <div className="d-flex flex-column px-3">
                  { enabledUserData.length > 0 && enabledUserData.map((item) => {
                    return (
                     <p className="text-center font-weight-bold">{`Are you sure you want to enable`}<span className="text-success">{` ${item.surname} ${item.firstName} ${item.otherNames}?`}</span></p>
                       )})}
                  </div>
                </div>
              </div>
            }
              <div class="modal-footer">
                <button data-bs-dismiss="modal" type="button" className="btn btn-secondary">Abort</button>
                { enabledUserData.length > 0 && enabledUserData.map((item) => {
                  return (
                <button id="closeButton" type="button" className="btn btn-success" onClick={() => this.enableUserStatus(item.userId)}>
                  {isEnabledLoading ?  <Spinner size="sm" animation="border" variant="light" /> : 'Enable'}
                </button>
                 )})}
              </div>
            </div>
          </div>
        </div>
        {/* End of Enable Modal */}

        {/* Start of Disable Modal  */}
        <div className="modal fade" id="disableModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header d-flex align-items-center justify-content-between">
                <h5 className="modal-title">Disable User</h5>
                <button type="button" className="btn btn-link m-0 p-0 text-dark fs-4" data-bs-dismiss="modal" aria-label="Close"><span className="iconify" data-icon="carbon:close"></span></button>
              </div>
              { isLoading ? <Spinner animation="border" style={{ position:'relative', right:0, left: 220, top: 0, padding: 10 }} className="text-center" variant="danger" size="sm" />  :
              <div className="modal-body">
                <div className="row">
                  <div className="d-flex px-3">
                    <div className="my-auto">
                      <img src={logo} class="avatar avatar-exbg  me-4 " alt="rubi moni"/>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="text-lg font-weight-normal mb-1">
                        <span className="font-weight-bold">Rubi Moni</span>
                      </h6>
                    </div>
                  </div>
                  <span className="pt-3"><hr class="dark horizontal my-3" /></span>
                  <div className="d-flex flex-column px-3">
                  { disabledUserData.length > 0 && disabledUserData.map((item) => {
                    return (
                     <p className="text-center font-weight-bold">{`Are you sure you want to disable`}<span className="text-danger">{` ${item.surname} ${item.firstName} ${item.otherNames}?`}</span></p>
                       )})}
                  </div>
                </div>
              </div>
            }
              <div className="modal-footer">
                <button data-bs-dismiss="modal" type="button" className="btn btn-secondary">Abort</button>
                { disabledUserData.length > 0 && disabledUserData.map((item) => {
                  return (
                <button id="closeButton" type="button" className="btn btn-success" onClick={() => this.disableUserStatus(item.userId)}>
                  {isDisabledLoading ?  <Spinner size="sm" animation="border" variant="light" /> : 'Disable'}
                </button>
                 )})}
              </div>
            </div>
          </div>
        </div>
        {/* End of Disable Modal */}
      </div>
    );
  }
}

export default Users;
