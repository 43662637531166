import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { baseUrl } from "../components/BaseUrl";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import Swal from "sweetalert2";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LoanOptions from "../components/LoanOptions";


class ApprovedLoans extends Component{
    constructor(props) {
        super(props);
        this.state = {
          approvedData: [],
          loadApproved: false,
        };
      }
    
      goApproved = async () => {
        $(document).ready(function() {
          setTimeout(function() {
            $("#repaid").DataTable({
              pagingType: "full_numbers",
              pageLength: 20,
              processing: true,
              dom: "Bfrtip",
              select: {
                style: "single",
              },
    
              lengthChange: false,
    
              buttons: [
                {
                  extend: "pageLength",
                  className: "btn btn-secondary bg-secondary",
                },
                {
                  extend: "copy",
                  className: "btn btn-secondary bg-secondary",
                },
                {
                  extend: "csv",
                  className: "btn btn-secondary bg-secondary",
                },
                {
                  extend: "print",
                  customize: function(win) {
                    $(win.document.body).css("font-size", "10pt");
                    $(win.document.body)
                      .find("table")
                      .addClass("compact")
                      .css("font-size", "inherit");
                  },
                  className: "btn btn-secondary bg-secondary",
                },
              ],
    
              fnRowCallback: function(
                nRow,
                aData,
                iDisplayIndex,
                iDisplayIndexFull
              ) {
                var index = iDisplayIndexFull + 1;
                $("td:first", nRow).html(index);
                return nRow;
              },
    
              lengthMenu: [
                [10, 20, 30, 50, -1],
                [10, 20, 30, 50, "All"],
              ],
              columnDefs: [
                {
                  targets: 0,
                  render: function(data, type, row, meta) {
                    return type === "export" ? meta.row + 1 : data;
                  },
                },
              ],
            });
          }, 1000);
        });
        this.setState({ loadApproved: true });
        let obj = {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("login"),
          },
        };
        await fetch(`${baseUrl}LoansMgt/GetLoansByStatus/approved`, obj)
          .then((response) => response.json())
          .then((responseJson) => {
            try {
              this.setState({
                loadApproved: false,
                approvedData: responseJson,
              });
            } catch (error) {
              Swal.fire({
                title: "Session expired",
                text: "Login",
                type: "error",
              }).then(function() {
                window.location.href = "/login";
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      };
    
      showApproved = () => {
        return this.state.approvedData.map((item) => {
          return (
            <tr style={{ wordBreak: "break-all" }}>
              <td>#</td>
              <td>{item.userId}</td>
              <td>{item.surname}</td>
              <td>{item.firstName}</td>
              <td>{item.otherNames}</td>
              <td>{item.loanId}</td>
              <td>{item.purpose}</td>
              <td>{item.applicationId}</td>
              <td>{item.principalAmount}</td>
              <td>{item.repaymentamount}</td>
              <td>{item.status}</td>
              <td>
                <select class="btn btn-lg" style={{width:'100%'}}>
                  <option>--Choose Action--</option>
                  <option>Repay with code</option>
                  <option>Approve with code</option>
                </select>
              </td>
            </tr>
          );
        });
      };
    
      componentDidMount() {
        this.goApproved();
      }
    
      render() {
        return (
          <div>
            <Header />
            <LoanOptions />
            <div className="container d-flex justify-content-center" style={{marginBottom: 60, marginTop: 45}}>
              {this.state.loadApproved ? (
                <Spinner animation="border" variant="dark" />
              ) : (
                <div className="container" style={{ marginTop: 18 }}>
                  <h3 className="text-center">Approved Loans </h3>
                  <div className="table-responsive" style={{ marginTop: 18 }}>
                    <table
                      id="repaid"
                      className="table display table-bordered table-striped"
                      style={{ tableLayout: "fixed", width: "160%" }}
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>User ID</th>
                          <th>Surname</th>
                          <th>First name</th>
                          <th>Other names</th>
                          <th>Loan ID</th>
                          <th>Purpose</th>
                          <th>Application ID</th>
                          <th>Principal Amount</th>
                          <th>Repayment Amount</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>{this.showApproved()}</tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
            <Footer />
          </div>
        );
      }
}

export default ApprovedLoans;