import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import logo from "../assets/images/logo.png";

class Header extends Component {

  componentDidMount(){
    console.warn(window.location.href);
  }

  render() {
    return (
      <div className="g-sidenav-show" style={{ padding: 0, margin: 0 }}>
      <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3" id="sidenav-main">
      <div className="sidenav-header">
        <i className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
        <a className="navbar-brand text-center m-0" href="#" target="_blank" style={{ backgroundColor: '#0F1D2D' }}>
          <img src={logo} className="navbar-brand-img h-100" alt="main_logo" />
        </a>
      </div>
      <hr className="horizontal light mt-0 mb-0" />
      <div className="collapse navbar-collapse  w-auto  max-height-vh-100" id="sidenav-collapse-main" style={{ height: '150vh', backgroundColor: '#0F1D2D' }}>
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link to="/dashboard" className={window.location.href.toString().includes("dashboard") ? 'active nav-link' : 'nav-link' }>
              <div className="text-center me-2 d-flex align-items-center justify-content-center">
                <i className="material-icons opacity-10 text-light">dashboard</i>
              </div>
              <span className="nav-link-text ms-1 text-light">Dashboard</span>
            </Link>
          </li>
          <li className="nav-item">
          <Link to="/active-loans" className={window.location.href.toString().includes("active-loans") ? 'active nav-link' : 'nav-link' }>
              <div className="text-center me-2 d-flex align-items-center justify-content-center">
                <i className="material-icons opacity-10 text-light">how_to_reg</i>
              </div>
              <span className="nav-link-text ms-1 text-light">Active Loans</span>
            </Link>
          </li>
          <li className="nav-item">
          <Link to="/repaid-loans" className={window.location.href.toString().includes("repaid-loans") ? 'active nav-link' : 'nav-link' }>
              <div className="text-center me-2 d-flex align-items-center justify-content-center">
                <i className="material-icons opacity-10 text-light">credit_score</i>
              </div>
              <span className="nav-link-text ms-1 text-light">Repaid Loans</span>

            </Link>
          </li>
          <li class="nav-item">
            <Link class="nav-link " to="/pending-loans" className={window.location.href.toString().includes("pending-loans") ? 'active nav-link' : 'nav-link' }>
              <div class="text-center me-2 d-flex align-items-center justify-content-center">
                <i class="material-icons opacity-10 text-light">pending_actions</i>
              </div>
              <span class="nav-link-text ms-1 text-light">Pending Loans</span>
            </Link>
          </li>
          <li class="nav-item">
            <Link to="/suspended-loans" className={window.location.href.toString().includes("suspended-loans") ? 'active nav-link' : 'nav-link' }>
              <div class="text-center me-2 d-flex align-items-center justify-content-center">
                <i class="material-icons opacity-10 text-light">business</i>
              </div>
              <span class="nav-link-text ms-1 text-light">Suspended Loans</span>
            </Link>
          </li>
          <li class="nav-item">
            <Link to="/update-ledger" className={window.location.href.toString().includes("update-ledger") ? 'active nav-link' : 'nav-link' }>
              <div class="text-center me-2 d-flex align-items-center justify-content-center">
                <i class="material-icons opacity-10 text-light">system_security_update_good</i>
              </div>
              <span class="nav-link-text ms-1 text-light">Update Loan Ledger</span>
            </Link>
          </li>

          <li class="nav-item">
            <Link to="/users" className={window.location.href.toString().includes("users") ? 'active nav-link' : 'nav-link' }>
              <div class="text-center me-2 d-flex align-items-center justify-content-center">
                <i class="material-icons opacity-10 text-light">how_to_reg</i>
              </div>
              <span class="nav-link-text ms-1 text-light">Manage users</span>
            </Link>
          </li>

          <li class="nav-item">
            <Link to="/edit-offers" className={window.location.href.toString().includes("edit-offers") ? 'active nav-link' : 'nav-link' }>
              <div class="text-center me-2 d-flex align-items-center justify-content-center">
                <i class="material-icons opacity-10 text-light">redeem</i>
              </div>
              <span class="nav-link-text ms-1 text-light">Manage Offers</span>
            </Link>
          </li>

          <li class="nav-item">
            <Link to="/create-offer" className={window.location.href.toString().includes("create-offer") ? 'active nav-link' : 'nav-link' }>
              <div class="text-center me-2 d-flex align-items-center justify-content-center">
                <i class="material-icons opacity-10 text-light">person_add_alt_1</i>
              </div>
              <span class="nav-link-text ms-1 text-light">Create Offers</span>
            </Link>
          </li>

          <li class="nav-item">
            <Link to="/generate-loan-code" className={window.location.href.toString().includes("generate-loan-code") ? 'active nav-link' : 'nav-link' }>
              <div class="text-center me-2 d-flex align-items-center justify-content-center">
                <i class="material-icons opacity-10 text-light">how_to_reg</i>
              </div>
              <span class="nav-link-text ms-1 text-light">Generate Loan Code</span>
            </Link>
          </li>

          <li class="nav-item">
            <Link to="/logout" className={window.location.href.toString().includes("logout") ? 'active nav-link' : 'nav-link' }>
              <div class="text-center me-2 d-flex align-items-center justify-content-center">
                <i class="material-icons opacity-10 text-light">logout</i>
              </div>
              <span class="nav-link-text ms-1 text-light">Logout</span>
            </Link>
          </li>

        </ul>
      </div>
    </aside>

    <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

      <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
        <div class="container-fluid py-1 px-3">
          <nav aria-label="breadcrumb">
            <h5 class="font-weight-bolder mb-0">Rubi Moni | {window.location.href.includes("dashboard") ? "Dashboard" : window.location.href.includes("repaid-loans") ? 'Repaid Loans' : window.location.href.includes("suspended-loans") ? 'Suspended Loans' : window.location.href.includes("update-ledger") ? 'Update Loan Ledger' : window.location.href.includes("users") ? 'Manage Users' : window.location.href.includes("edit-offers") ? 'Manage Offers' : window.location.href.includes("workflow") ? 'Workflow Management' : window.location.href.includes("logout") ? 'Logout' : window.location.href.includes("active-loans") ? 'Active Loans' : window.location.href.includes("pending-loans") ? 'Pending Loans' : window.location.href.includes("generate-loan-code") ? 'Generate Loan Code' : window.location.href.includes("create-offer") ? 'Create Offer' : ''}</h5>
          </nav>
          <ul class="navbar-nav  justify-content-end">
            <li>
              <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                <div class="ms-md-auto d-flex">
                  <li class="nav-item dropdown d-flex align-items-center">
                    <a href="#" class="nav-link text-body" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                      <span class="my-auto">
                        <img src="../assets/img/account.svg" class="avatar avatar-sm me-3 " />
                      </span>
                      <i class="fa fa-angle-down cursor-pointer"></i>
                    </a>
                    <ul class="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
                    {/*  <li class="mb-2">
                        <a class="dropdown-item border-radius-md" href="javascript:;">
                          <div class="d-flex py-1">
                            <div class="my-auto">
                              <img src="../assets/img/account.svg" class="avatar avatar-sm  me-3 " />
                            </div>
                            <div class="d-flex flex-column justify-content-center">
                              <h6 class="text-sm font-weight-normal mb-1">
                                <span class="font-weight-bold">Profile</span>
                              </h6>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li class="mb-2">
                        <a class="dropdown-item border-radius-md" href="javascript:;">
                          <div class="d-flex py-1">
                            <div class="my-auto">
                              <img src="../assets/img/message.svg" class="avatar avatar-sm  me-3 " />
                            </div>
                            <div class="d-flex flex-column justify-content-center">
                              <h6 class="text-sm font-weight-normal mb-1">
                                <span class="font-weight-bold">Mails</span>
                              </h6>
                            </div>
                          </div>
                        </a>
                      </li> */}
                      <li class="mb-2">
                        <Link class="dropdown-item border-radius-md" to="/logout">
                          <div class="d-flex py-1">
                            <div class="my-auto">
                              <img src="../assets/img/logout.svg" class="avatar avatar-sm me-3 " />
                            </div>
                            <div class="d-flex flex-column justify-content-center">
                              <h6 class="text-sm font-weight-normal mb-1">
                                <span class="font-weight-bold text-danger">Logout</span>
                              </h6>
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
                    <a href="javascript:;" class="nav-link text-body p-0" id="iconNavbarSidenav">
                      <div class="sidenav-toggler-inner">
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                      </div>
                    </a>
                  </li>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      </main>
       </div>
    );
  }
}

export default Header;
