import React, { Component } from "react";
import Swal from "sweetalert2";
import { Spinner, Table } from "react-bootstrap";
import { baseUrl } from "../components/BaseUrl";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import profileImage from '../assets/images/profile.png';
import logo from '../assets/images/logo.png';
import moment from 'moment';
import NumberFormat from 'react-number-format';

//Registration
//Loan offficer
//Admin

class EditOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadOffers: false,
      searchOffers: false,
      dataOffers: [],
      loanOffersData: [],
      selectValue: "",
      showModal: false,
      showOffersTable: false,
      stageId: "",
      loanId: "",
      interestId: "",
      maxTenure: "",
      minTenure: "",
      stageName: "",
      principal: "",
      penalty: "",
      interest: "",
      isDisabled: false,
      isLoading: false,
      isUpdateLoading: false,
      postsPerPage: 10,
      currentPage: 1,
    };
  }

  updateOffer = async (stageName) => {
    const { loanId, principal, interest, penalty, minTenure, maxTenure } = this.state;
    this.setState({ isUpdateLoading: true, isDisabled: true })
    // console.warn(this.state.stageName);
    const value = stageName.split(",")
    // console.warn(stageName);

    var obj = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
      body: JSON.stringify({
        interest: interest === "" ? value[3] : interest,
        // interestId: interestId === "" ? value[8] : interestId,
        loanId: loanId === "" ? value[7] : loanId,
        maxTenure: maxTenure === "" ? value[6] : maxTenure,
        minTenure: minTenure === "" ? value[5] : minTenure,
        stageId:   this.state.stageId === "" ? value[1] : this.state.stageId,
        stageName: this.state.stageName === "" ? value[0] : this.state.stageName,
        principal: principal === "" ? value[2] : principal,
        penalty: penalty === "" ? value[4] : penalty,
      }),
    };
    // console.warn(`${interest} ${loanId} ${maxTenure} ${minTenure} ${this.state.stageId} ${stageName} ${principal} ${penalty}`);
    await fetch(`${baseUrl}Offers/updateOffer`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.warn(responseJson);
        if (responseJson.message === "Offer Updated Succesfully") {
          Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Update successful',
          showConfirmButton: false,
          timer: 1500
        }).then(()=>{
          window.location.reload("/edit-offers")
        })
          this.setState({isUpdateLoading:false, isDisabled: false });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({isUpdateLoading:false, isDisabled: false });
      });
  }


  getOfferDetails = (stageId) => {
    const url = `${baseUrl}Offers/stageid/${stageId}`;
    this.setState({isLoading: true});
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    })
      .then(res => res.json())
      .then(res => {
        // console.warn(res);
        this.setState({
          isLoading: false,
          loanOffersData: res,
        });
      })
      .catch(error => {
        this.setState({error: true, loading: false});
        alert(error);
      });
  }

  updateOfferDetails = (stageId) => {
    // console.warn(stageId);
    const url = `${baseUrl}Offers/stageid/${stageId}`;
    this.setState({isLoading: true});
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    })
      .then(res => res.json())
      .then(res => {
        // console.warn(res);
        this.setState({
          isLoading: false,
          loanOffersData: res,
        });
      })
      .catch(error => {
        this.setState({error: true, loading: false});
        alert(error);
      });
  }


  getOffers = async () => {
    if (localStorage.getItem("login") === "") {
      window.location.href = "/login";
    }
    this.setState({ loadOffers: true });
    $(document).ready(function() {});

    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    };

    await fetch(`${baseUrl}Offers/Admin/getAllOffers`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.warn(responseJson)
        try {
          responseJson.map((item) => {
            this.setState({ loanId: item.loanId });
          });
          this.setState({
            loadOffers: false,
            dataOffers: responseJson,
          });
        } catch (e) {
          Swal.fire({
            title: "Session expired",
            text: "Login",
            type: "error",
          }).then(function() {
            window.location.replace("/login")
            // this.props.history.push("/login")
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  showOffersTable = () => {
    const { postsPerPage, currentPage, dataOffers } = this.state;
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = dataOffers.length > 0 ? dataOffers.slice(indexOfFirstPost, indexOfLastPost) : null;
    try {
      return currentPosts.map((item, index) => {
        return (
          <tbody>
            <tr>
              <td className="text-xs font-weight-bold">{index+1}</td>
              <td className="text-xs font-weight-bold">{item.stageId}</td>
              <td className="text-xs font-weight-bold">{item.stageName}</td>
              <td className="text-xs font-weight-bold">
              &#8358;
              <NumberFormat
              value={item.principal}
              displayType={'text'}
              thousandSeparator={true}
              renderText={value => <span> {value}</span>}
              />
              </td>
              <td className="text-xs font-weight-bold">{item.penalty}</td>
              <td className="text-xs font-weight-bold">{item.interest}</td>
              <td className="text-xs font-weight-bold">{item.maxTenure}</td>
              <td className="text-xs font-weight-bold">{item.minTenure}</td>
              <td>
               <button className="btn btn-primary-2 mb-0" id="viewRepaid" data-bs-toggle="dropdown" aria-expanded="false"><span class="iconify" data-icon="charm:menu-meatball" style={{fontSize: 'large'}} ></span></button>
               <ul id = { item.stageId } onClick={() => this.getOfferDetails(item.stageId)} className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="#dropdownMenuButton2">
                 <li className="mb-2" data-bs-toggle="modal" data-bs-target="#viewOffers">
                   <a className="dropdown-item border-radius-md" href="javascript:;">
                     <div className="d-flex py-1" >
                         <h6 className="text-sm font-weight-normal mb-1">
                           <span className="font-weight-bold">View</span>
                         </h6>
                     </div>
                   </a>
                 </li>
                 <li className="mb-2">
                   <a className="dropdown-item border-radius-md" href="javascript:;">
                     <div className="d-flex py-1" data-bs-toggle="modal" data-bs-target="#updateOffers">
                         <h6 className="text-sm font-weight-normal mb-1">
                           <span id = { item.stageId } onClick={() => this.updateOfferDetails(item.stageId)} className="font-weight-bold">Update</span>
                         </h6>
                     </div>
                   </a>
                 </li>
               </ul>
               </td>

            </tr>
          </tbody>
        );
      });
    } catch (e) {
      // Swal.fire({
      //   title: "Session expired",
      //   text: "Login",
      //   type: "error",
      // }).then(function() {
      //   window.location.href = "/login";
      // });
    }
  };

  showPagination = () => {
    const { postsPerPage, dataOffers } = this.state;
    const pageNumbers = [];
    const totalPosts = dataOffers.length;
    for(let i = 1; i<= Math.ceil(totalPosts/postsPerPage); i++){
      pageNumbers.push(i)
    }

   const paginate = (pageNumbers) => {
     this.setState({currentPage: pageNumbers})
   }

    return(
      <nav>
      <ul className="pagination">
      {pageNumbers.map(number => (
        <li key={number} className={this.state.currentPage === number ? 'page-item active' : 'page-item'}>
        <button onClick={()=> paginate(number)} className="page-link">
          { number }
        </button>
       </li>
     ))}
      </ul>
      </nav>
    )
  }

  componentDidMount() {
    this.getOffers();
  }

  render() {
    const { isLoading, isDisabled, isUpdateLoading, loanOffersData } = this.state;
    return (
      <div style={{ width: '100%' }}>
        <Header />
        <div
          className="container d-flex justify-content-center"
          style={{ marginTop: 27, marginBottom: 45, marginLeft: 150 }}
        >
          {this.state.loadOffers ? (
            <Spinner
              style={{ marginTop: 150 }}
              animation="border"
              variant="dark"
            />
          ) : (
            <div
              className="container-flex justify-content-end table-responsive" style={{padding: 18, marginLeft: 0 }}
            >
                <table
                  id="offers"
                  className="table"
                >
                  <thead>
                    <tr>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">S/N</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Stage ID</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Stage Name</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Principal</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Penalty</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Interest</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Max Tenure</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Min Tenure</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Action</th>
                    </tr>
                  </thead>

                  {this.showOffersTable()}
                </table>
                <div style={{float: 'right'}}>
                {this.showPagination()}
                </div>
            </div>
          )}
        </div>

        {/* Start of View Offers Modal */}
        <div className="modal fade" id="viewOffers" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header d-flex align-items-center justify-content-between">
                <h5 className="modal-title">Offer Details</h5>
                <button type="button" className="btn btn-link m-0 p-0 text-dark fs-4" data-bs-dismiss="modal" aria-label="Close"><span class="iconify" data-icon="carbon:close"></span></button>
              </div>
                { isLoading ? <Spinner animation="border" style={{ position:'relative', right:0, left: 220, top: 0 }} className="text-center" variant="danger" size="sm" />  :
              <div class="modal-body">
              { loanOffersData.length > 0 && loanOffersData.map((item) => {
                return (
                <div className="row">
                  <div className="d-flex px-3">
                    <div className="my-auto">
                      <img src={logo} className="avatar avatar-exbg  me-4 " />
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="text-lg font-weight-normal mb-1">
                        <span className="font-weight-bold text-dark">{item.stageName}</span>
                      </h6>
                    </div>
                  </div>
                  <span className="pt-1"><hr className="dark horizontal my-3" /></span>
                  <div className="d-flex flex-column px-3">
                    <h6 className="mb-3 text-sm">Offer History</h6>
                    <span className="mb-2 text-xs">Stage Id: <span className="text-success ms-sm-2 font-weight-bold">{item.stageId}</span></span>
                    <span className="mb-2 text-xs">Loan Id: <span className="text-success ms-sm-2 font-weight-bold">{item.LoanId}</span></span>
                    <span className="mb-2 text-xs">Principal: <span className="text-dark font-weight-bold ms-sm-2">
                    &#8358;
                    <NumberFormat
                    value={item.principal}
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={value => <span> {value}</span>}
                    />
                    </span></span>
                    <span className="mb-2 text-xs">Interest: <span className="text-dark font-weight-bold ms-sm-2">{item.interest}</span></span>
                    <span className="mb-2 text-xs">Penalty: <span className="text-dark font-weight-bold ms-sm-2">{item.penalty}</span></span>
                    <span className="mb-2 text-xs">Minimum Tenure: <span className="text-dark font-weight-bold ms-sm-2">{item.minTenure}</span></span>
                    <span className="mb-2 text-xs">Maximum Tenure: <span className="text-dark font-weight-bold ms-sm-2">{item.maxTenure}</span></span>
                  </div>
                </div>
              )})}
              </div>
              }
              <div className="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* End of View Offers Modal */}

        {/* Start of Update Offers Modal */}
        <div className="modal fade" id="updateOffers" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header d-flex align-items-center justify-content-between">
                <h5 className="modal-title">Edit Offer</h5>
                <button type="button" className="btn btn-link m-0 p-0 text-dark fs-4" data-bs-dismiss="modal" aria-label="Close"><span class="iconify" data-icon="carbon:close"></span></button>
              </div>
                { isLoading ? <Spinner animation="border" style={{ position:'relative', right:0, left: 220, top: 0 }} className="text-center" variant="danger" size="sm" />  :
              <div class="modal-body">
              { loanOffersData.length > 0 && loanOffersData.map((item) => {
                return (
                <div className="row">
                  <div className="d-flex px-3">
                    <div className="my-auto">
                      <img src={logo} className="avatar avatar-exbg  me-4 " />
                    </div>

                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="text-lg font-weight-normal mb-1">
                        <span className="font-weight-bold text-dark">Edit individual offers</span>
                      </h6>
                    </div>

                  </div>
                  <span className="pt-1"><hr className="dark horizontal my-3" /></span>
                    <div className="d-flex flex-row p-4">

                    <div className="row">
                    <div className="col-sm-6 col-lg-4 col-md-4 mb-3">
                    <label className="mb-2 form-label">Offer Name</label> <input type="text" defaultValue={item.stageName}
                    onChange={(e) => this.setState({ stageName: e.target.value })} className="form-control" />
                    </div>


                    <div className="col-sm-6 col-lg-4 col-md-4 mb-3">
                    <label className="mb-2 form-label">Stage Id</label> <input type="text" defaultValue={item.stageId} onChange={(e) => this.setState({ stageId: e.target.value })} className="form-control" />
                    </div>
                    </div>

                    <div className="row">
                    <div className="col-sm-6 col-lg-4 col-md-4 mb-3">
                    <label className="mb-2 form-label">Principal</label> <input type="text" defaultValue={item.principal} onChange={(e) => this.setState({ principal: e.target.value })} className="form-control" />
                    </div>

                    <div className="col-sm-6 col-lg-4 col-md-4 mb-3">
                    <label className="mb-2 form-label">Interest</label> <input type="text" defaultValue={item.interest} onChange={(e) => this.setState({ interest: e.target.value })} className="form-control" />
                    </div>
                    </div>

                    <div className="row">
                    <div className="col-sm-6 col-lg-4 col-md-4 mb-3">
                    <label className="mb-2 form-label">Penalty</label> <input type="text" defaultValue={item.penalty} onChange={(e) => this.setState({ penalty: e.target.value })} className="form-control" />
                    </div>

                    <div className="col-sm-6 col-lg-4 col-md-4 mb-3">
                    <label className="mb-2 form-label">min. Tenure</label> <input type="text" defaultValue={item.minTenure} onChange={(e) => this.setState({ minTenure: e.target.value })} className="form-control" />
                    </div>
                    </div>

                    <div className="row">
                    <div className="col-sm-6 col-lg-6 col-md-8 mb-3">
                    <label className="mb-2 form-label">max.Tenure</label> <input type="text" defaultValue={item.maxTenure} onChange={(e) => this.setState({ maxTenure: e.target.value })} className="form-control" />
                    </div>
                    <div className="col-sm-6 col-lg-6 col-md-8 mb-3" style={{ visibility: 'hidden' }}>
                    <label className="mb-2 form-label">Interest Id</label> <input type="text" defaultValue={item.InterestId} onChange={(e) => this.setState({ interestId: e.target.value })} className="form-control" />
                    </div>

                    </div>

                    <div className="row">

                    <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                    <label className="mb-2 form-label">Loan Id</label> <input type="text" defaultValue={item.LoanId} className="form-control" />
                    </div>
                    <div className="col-sm-6 col-lg-4 col-md-4 mt-4">
                     <button disabled={isDisabled} id={`${item.stageName}`} onClick={()=>this.updateOffer(`${item.stageName}, ${item.stageId}, ${item.principal}, ${item.interest}, ${item.penalty}, ${item.minTenure}, ${item.maxTenure}, ${item.LoanId}, ${item.InterestId}`)} className="btn btn-lg btn-success">
                     {isUpdateLoading ? (
                       <Spinner animation="border" variant="light" size="sm" />
                     ) : (
                       "Submit"
                     )}
                     </button>
                    </div>
                    </div>

                  </div>
                </div>
              )})}
              </div>
              }
              <div className="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* End of Update Offers Modal */}

      </div>
    );
  }
}

export default EditOffers;
