import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';

class OfferOptions extends Component {
  render() {
    return (
      <div className="searchContainer">
        {/* <Link to="/loan-admin">
          <Button variant={"primary"} size="lg">
            Available Offers
          </Button>
        </Link> */}

        {/* <Link to="/search-offers-by-id">
          <Button variant={"primary"} size="lg">
            Search Offers By ID
          </Button>
        </Link> */}
        {/* <Link to="/search-users-by-offers">
          <Button variant={"primary"} size="lg">
            Search Users By Offer
          </Button>
        </Link> */}
        <Link to="/edit-offers">
          <Button variant={"primary"} size="lg">
            Edit Offers
          </Button>
        </Link>
      </div>
    );
  }
}

export default OfferOptions;
