import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';

class UserOptions extends Component {
    render(){
        return(
            <div className="searchContainer">
            <Link to="/users">
              <Button variant={"primary"} size="lg">
                All Users
              </Button>
            </Link>
    
            <Link to="/public-users">
              <Button variant={"primary"} size="lg">
                Public Users
              </Button>
            </Link>
            <Link to="/private-users">
              <Button variant={"primary"} size="lg">
                Private Users
              </Button>
            </Link>
          </div>
        )
    }
}

export default UserOptions;