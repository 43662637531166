import React, { Component } from 'react';
import Header from "../components/Header";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { baseUrl } from "../components/BaseUrl";

class CreateOffer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      stageName: "",
      stageID: "",
      interestID: "",
      interest: "",
      loanID: "",
      maxTenure: "",
      minTenure: "",
      penalty: "",
      principal: "",
      isLoading: false,
      isDisabled: false,
    }
  }

  createOffer = () => {
    const { stageName, stageID, interestID, interest, loanID, maxTenure, minTenure, penalty, principal } = this.state;
    if(stageName === "" ){
      Swal.fire({
        title: "Error",
        text: "Please enter STAGE NAME",
        icon: "error",
        confirmButtonText: "OK",
      });
    }else if(stageID === ""){
      Swal.fire({
        title: "Error",
        text: "Please enter STAGE ID",
        icon: "error",
        confirmButtonText: "OK",
      });
    }else if(interestID === ""){
      Swal.fire({
        title: "Error",
        text: "Please enter INTEREST ID",
        icon: "error",
        confirmButtonText: "OK",
      });
    }else if(loanID === ""){
      Swal.fire({
        title: "Error",
        text: "Please enter LOAN ID",
        icon: "error",
        confirmButtonText: "OK",
      });
    }else if(maxTenure === ""){
      Swal.fire({
        title: "Error",
        text: "Please enter MAX TENURE",
        icon: "error",
        confirmButtonText: "OK",
      });
    }else if(minTenure === ""){
      Swal.fire({
        title: "Error",
        text: "Please enter MIN TENURE",
        icon: "error",
        confirmButtonText: "OK",
      });
    }else if(penalty === ""){
      Swal.fire({
        title: "Error",
        text: "Please enter PENALTY value",
        icon: "error",
        confirmButtonText: "OK",
      });
    }else if(principal === ""){
      Swal.fire({
        title: "Error",
        text: "Please enter PRINCIPAL VALUE",
        icon: "error",
        confirmButtonText: "OK",
      });
    }else if(principal === ""){
      Swal.fire({
        title: "Error",
        text: "Please enter PRINCIPAL VALUE",
        icon: "error",
        confirmButtonText: "OK",
      });
    }else{
      this.setState({ isLoading: true, isDisabled: true });
      var obj = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("login"),
        },
        body: JSON.stringify({
          interest: interest,
          interestId: interestID,
          loanId: loanID,
          maxTenure: maxTenure,
          minTenure: minTenure,
          penalty: penalty,
          principal: principal,
          stageId: stageID,
          stageName: stageName
        }),
      };
       fetch(`${baseUrl}Offers/createOffer`, obj)
        .then((response) => response.json())
        .then((responseJson) => {
          // console.warn(responseJson);
          if (responseJson.message === "Offer Added Succesfully") {
            this.setState({ isLoading: false, isDisabled: false });
            Swal.fire({
              title: "Success",
              text: responseJson.message,
              icon: "success",
              confirmButtonText: "OK",
            }).then(function() {
              this.props.history.push("/edit-offers")
            })
          } else {
            this.setState({ isLoading: false });
            Swal.fire({
              title: "Error",
              text: responseJson.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false, isDisabled: false });
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  }

  render() {
    const { isLoading, isDisabled } = this.state;
    return (
      <div>
      <Header />
      <div className="container d-flex justify-content-center" style={{ width: '70%', position: 'relative', left: 150 }}>
      <div class="card-body">
      <ul class="list-group">
     <li class="list-group-item border-0 p-4 mb-2 bg-gray-100 border-radius-lg">
      <h6 class="mb-3 text-sm">Offer Information</h6>
      <div class="row">
        <div class="col-md-6">
        <label class="form-label">Stage Name</label>
          <div class="input-group input-group-outline mb-3">
            <input type="text" class="form-control" onChange={e => this.setState({ stageName: e.target.value })} />
          </div>
        </div>
        <div class="col-md-6">
        <label class="form-label">Stage ID</label>
          <div class="input-group input-group-outline mb-3">
            <input type="text" class="form-control" onChange={e => this.setState({ stageID: e.target.value })} />
          </div>
        </div>
        <div class="col-md-6">
            <label class="form-label">Interest ID</label>
          <div class="input-group input-group-outline mb-3">
            <input type="text" class="form-control" onChange={e => this.setState({ interestID: e.target.value })}/>
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">Loan ID</label>
          <div class="input-group input-group-outline mb-3">
            <input type="text" class="form-control" onChange={e => this.setState({ loanID: e.target.value })}/>
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">Max Tenure</label>
          <div class="input-group input-group-outline mb-3">
            <input type="text" class="form-control" onChange={e => this.setState({ maxTenure: e.target.value })}/>
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">Min Tenure</label>
          <div class="input-group input-group-outline mb-3">
            <input type="text" class="form-control" onChange={e => this.setState({ minTenure: e.target.value })}/>
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">Penalty</label>
          <div class="input-group input-group-outline mb-3">
            <input type="text" class="form-control" onChange={e => this.setState({ penalty: e.target.value })}/>
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">Principal</label>
          <div class="input-group input-group-outline mb-3">
            <input type="text" class="form-control" onChange={e => this.setState({ principal: e.target.value })}/>
          </div>
        </div>
        <div class="col-md-6">
          <label class="form-label">Interest</label>
          <div class="input-group input-group-outline mb-3">
            <input type="text" class="form-control" onChange={e => this.setState({ interest: e.target.value })}/>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <div class="text-end mt-4">
    <button disabled={isDisabled} onClick={() => this.createOffer()} class="btn btn-success btn-lg mb-0">
    {isLoading ?  <Spinner size="sm" animation="border" variant="light" /> : 'CREATE OFFER'}
    </button>
  </div>
</div>
</div>

      </div>
    );
  }
}

export default CreateOffer;
