import React, { Component } from "react";
import "../styles/App.css";
import moment from 'moment';

class Footer extends Component {
  render() {
    return (
      // <div>
        <footer className="footer-area" style={{marginTop: 300}}>
          <div className="mini-footer">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="copyright-text">
                    <h5 className="text-light">
                      © {moment(this.props.dateToFormat).format('YYYY')} |  Ruby Moni | All rights reserved.
                    </h5>
                  </div>
                  <div className="go_top">
                    <span className="icon-arrow-up"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      // </div>
    );
  }
}

export default Footer;
