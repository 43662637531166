import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { baseUrl } from "../components/BaseUrl";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NumberFormat from 'react-number-format';
import moment from 'moment';
import profileImage from '../assets/images/profile.png';
import logo from '../assets/images/logo.png';


class Loans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myData: [],
      loadPending: false,
      isLoading: false,
      isApprovedLoading: false,
      isApprovedWithCodeLoading: false,
      userPendingData: [],
      approvedUserData: [],
      loanCode: "",
      accountName: "",
      accountNumber: "",
      bankCode: "",
      applicationId: "",
      approve_application_id: "",
      approve_user_id: ""
    };
  }

  getUserBankDetails = () => {
    const url = `${baseUrl}Banks/userid/${this.state.approve_user_id}`;
    this.setState({isLoading: true});
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    })
      .then(res => res.json())
      .then(res => {
        console.warn(res);
        if(res){
        res.map((item) => {
        if(item.AccountName !== ""){
          this.setState({ accountName: item.AccountName, accountNumber: item.AccountNumber, bankCode: item.Bankcode})
          this.initializeTransfer();
        }else{
          alert("An error occurred. Please try again");
        }
              })
      }

      })
      .catch(error => {
        this.setState({error: true, loading: false});
        alert(error);
      });
  }

  initializeTransfer = async () => {
    this.setState({ isApprovedWithCodeLoading: true });
    const { accountName, accountNumber, bankCode } = this.state;
    console.warn(this.state.approve_application_id);
    console.warn(this.state.accountName);
    console.warn(this.state.accountNumber);
    console.warn(this.state.bankCode);
      // let obj = {
      //   method: "POST",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //     Authorization: 'Bearer ' + localStorage.getItem("login"),
      //   },
      //   body: JSON.stringify({
      //     applicationid:this.state.approve_application_id.trim(),
      //     accountname:this.state.accountName.trim(),
      //     accountnumber:this.state.accountNumber.trim(),
      //     bankcode:this.state.bankCode.trim()
      //   }),
      // };
       fetch(`${baseUrl}Loans/initializeTransfer`, {
         method: 'POST',
         headers: {
           Accept: 'application/json',
           'Content-Type': 'application/json',
           Authorization: 'Bearer ' + localStorage.getItem("login"),
         },
         body: JSON.stringify({
           applicationid: "LOAN120220819101721154",
           accountname: "Ajiteru Folake Olubisi",
           accountnumber: "0118277197",
           bankcode: "058"
         }),
       })
        .then(response => response.json())
        .then(responseJson => {
          console.warn(responseJson);
          if (responseJson.message === "Transfer has been queued") {
            this.setState({ isApprovedLoading: false });
            Swal.fire({
              title: "Success",
              text: responseJson.message,
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              //initialize transfer
              this.props.history.push("/pending-loans")
            })
          } else {
            this.setState({ isApprovedLoading: false });
            Swal.fire({
              title: "Error",
              text: responseJson.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }


  getApprovedUserDetails = (userId) => {
    const url = `${baseUrl}Loans/userid/${userId}`;
    this.setState({isLoading: true, approve_user_id: userId});
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    })
      .then(res => res.json())
      .then(res => {
        // console.warn(res);
        this.setState({
          isLoading: false,
          approve_application_id: res.applicationId,
          approvedUserData: [res],
        });
      })
      .catch(error => {
        this.setState({error: true, loading: false});
        alert(error);
      });
  }

  getUserPendingDetails = (userId) => {
    const url = `${baseUrl}Loans/userid/${userId}`;
    this.setState({isLoading: true});
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    })
      .then(res => res.json())
      .then(res => {
        // console.warn(res);
        this.setState({
          isLoading: false,
          userPendingData: [res],
        });
      })
      .catch(error => {
        this.setState({error: true, loading: false});
        alert(error);
      });
  }

  approveWithCode = async (applicationId) => {
    this.setState({ isApprovedWithCodeLoading: true });
    this.setState({ approve_application_id: applicationId });
    const { loanCode } = this.state;

    if(loanCode === ""){
      Swal.fire({
        title: "Error",
        text: "Please enter LOAN CODE",
        icon: "error",
        confirmButtonText: "OK",
      });
    }else{
      var obj = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("login"),
        },
        body: JSON.stringify({
          applicationId: applicationId,
          loancode: loanCode,
        }),
      };
       fetch(`${baseUrl}Loans/approved`, obj)
        .then((response) => response.json())
        .then((responseJson) => {
          console.warn(responseJson);
          if (responseJson.Message === "Loan Application Approved") {
            // this.setState({ isApprovedLoading: false });
            // Swal.fire({
            //   title: "Success",
            //   text: responseJson.message,
            //   icon: "success",
            //   confirmButtonText: "OK",
            // }).then(() => {
            //   this.getUserBankDetails();
            //   //initialize transfer
            //   // this.props.history.push("/pending-loans")
            // })
            this.getUserBankDetails();
          } else {
            this.setState({ isApprovedLoading: false });
            Swal.fire({
              title: "Error",
              text: responseJson.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }

  }

   generateLoanCode = async (applicationId) => {
    this.setState({ isApprovedWithCodeLoading: true });
    this.setState({ approve_application_id: applicationId });
    if (localStorage.getItem("login") === "") {
      this.props.history.push("/login")
    }
      var obj = {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("login"),
        },
        body: JSON.stringify({
          applicationId: `${applicationId}`,
        }),
      };
      await fetch(`${baseUrl}Loans/generateCode`, obj)
        .then((response) => response.json())
        .then((responseJson) => {
          console.warn(responseJson);
          if (responseJson.message === "Loan Activated") {
            this.approveWithCode();
            // loanCode = responseJson.loanCode;
            // applicationId = responseJson.applicationId;
            // // this.approveLoan();
            // var req = {
            //   method: "POST",
            //   headers: {
            //     Accept: "application/json",
            //     "Content-Type": "application/json",
            //     Authorization: "Bearer " + localStorage.getItem("login"),
            //   },
            //   body: JSON.stringify({
            //     applicationId: `${applicationId}`,
            //     loancode: responseJson.loancode,
            //   }),
            // };
            //  fetch(`${baseUrl}Loans/approved`, req)
            //   .then((response) => response.json())
            //   .then((responseJson) => {
            //     console.warn(responseJson);
            //     if (responseJson.Message === "Loan Application Approved") {
            //       this.getUserBankDetails();
            //       // Swal.fire({
            //       //   title: "Success",
            //       //   text: responseJson.message,
            //       //   icon: "success",
            //       //   confirmButtonText: "OK",
            //       // }).then(() => {
            //       //   this.props.history.push("/login")
            //       // })
            //     } else {
            //       this.setState({ isApprovedLoading: false });
            //       Swal.fire({
            //         title: "Error",
            //         text: responseJson.message,
            //         icon: "error",
            //         confirmButtonText: "OK",
            //       });
            //     }
            //   })
            //   .catch((error) => {
            //     Swal.fire({
            //       title: "Error!",
            //       text: error.message,
            //       icon: "error",
            //       confirmButtonText: "OK",
            //     });
            //   });
          } else {
            this.setState({ isApprovedLoading: false});
            Swal.fire({
              title: "Something went wrong",
              text: responseJson.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
        this.setState({ isApprovedLoading: false });
  }

  // goGenerateLoanCode = () => {
  //   this.props.history.push("/generate-loan-code");
  // }


  goPending = async () => {
    if (localStorage.getItem("login") === "") {
      this.props.history.push("/login");
    }
    $(document).ready(function() {
      setTimeout(function() {
        $("#myTable").DataTable({
          pagingType: "full_numbers",
          pageLength: 20,
          processing: true,
          dom: "Bfrtip",
          select: {
            style: "single",
          },

          lengthChange: false,

          buttons: [
            {
              extend: "pageLength",
              className: "btn btn-success bg-success",
            },
            {
              extend: "copy",
              className: "btn btn-success bg-success",
            },
            {
              extend: "csv",
              className: "btn btn-primary bg-primary",
            },
            {
              extend: "print",
              customize: function(win) {
                $(win.document.body).css("font-size", "10pt");
                $(win.document.body)
                  .find("table")
                  .addClass("compact")
                  .css("font-size", "inherit");
              },
              className: "btn btn-danger bg-danger",
            },
          ],

          fnRowCallback: function(
            nRow,
            aData,
            iDisplayIndex,
            iDisplayIndexFull
          ) {
            var index = iDisplayIndexFull + 1;
            $("td:first", nRow).html(index);
            return nRow;
          },

          lengthMenu: [
            [10, 20, 30, 50, -1],
            [10, 20, 30, 50, "All"],
          ],
          columnDefs: [
            {
              targets: 0,
              render: function(data, type, row, meta) {
                return type === "export" ? meta.row + 1 : data;
              },
            },
          ],
        });
      }, 1000);
    });
    this.setState({ loadPending: true });
    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    };
    await fetch(`${baseUrl}LoansMgt/GetLoansByStatus/pending`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.warn(responseJson);
        try {
          this.setState({
            loadPending: false,
            myData: responseJson,
          });
        } catch (error) {
          Swal.fire({
            title: "Session expired",
            text: "Login",
            type: "error",
          }).then(() => {
            this.props.history.push("/login");
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Session expired",
          text: "Login",
          type: "error",
        }).then(() => {
          this.props.history.push("/login");
        });
      });
  };

  showTablePending = () => {
    try {
      return this.state.myData.map((item, index) => {
        return (
          <tr>
            <td className="text-xs font-weight-bold">{index+1}</td>
            <td className="text-xs font-weight-bold">{`${item.surname} ${item.firstName} ${item.otherNames}`}</td>
            <td className="text-xs font-weight-bold">
            &#8358;
            <NumberFormat
            value={item.principalAmount}
            displayType={'text'}
            thousandSeparator={true}
            renderText={value => <span> {value}</span>}
            /></td>
            <td className="text-xs font-weight-bold">
            &#8358;
            <NumberFormat
            value={item.repaymentamount}
            displayType={'text'}
            thousandSeparator={true}
            renderText={value => <span> {value}</span>}
            /></td>
          <td><button className={item.status === "pending" ? "btn btn-warning text-light text-xs font-weight-bold" : null}>{item.status}</button></td>
            <td className="text-xs font-weight-bold">{moment(item.dateApplied).format('LL') ==="Invalid date" ? "" : moment(item.dateApplied).format('LL')}</td>
            {/*<td className="text-xs font-weight-bold">{moment(item.dateApproved).format('LL') ==="Invalid date" ? "" : moment(item.dateApproved).format('LL')}</td>
            <td className="text-xs font-weight-bold">{moment(item.dateRepaid).format('LL') ==="Invalid date" ? "" : moment(item.dateRepaid).format('LL')}</td> */}
            <td>
           <button className="btn btn-primary-2 mb-0" id="viewRepaid" data-bs-toggle="dropdown" aria-expanded="false"><span class="iconify" data-icon="charm:menu-meatball" style={{fontSize: 'large'}} ></span></button>
           <ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="#dropdownMenuButton2">
             <li className="mb-2" id = { item.userId } onClick={() => this.getUserPendingDetails(item.userId)} data-bs-toggle="modal" data-bs-target="#viewPending">
               <a className="dropdown-item border-radius-md" href="javascript:;">
                 <div className="d-flex py-1">
                     <h6 className="text-sm font-weight-normal mb-1">
                       <span className="font-weight-bold" >View</span>
                     </h6>
                 </div>
               </a>
             </li>
             {/*
             <li className="mb-2" id = { item.userId } onClick={() => this.getApprovedUserDetails(item.userId)} data-bs-toggle="modal" data-bs-target="#viewApprovedModal">
               <a className="dropdown-item border-radius-md" href="javascript:;">
                 <div className="d-flex py-1">
                     <h6 className="text-sm font-weight-normal mb-1">
                       <span className="font-weight-bold" >Approve</span>
                     </h6>
                 </div>
               </a>
             </li>
             */}
             <li className="mb-2" id = { item.userId } onClick={() => this.getApprovedUserDetails(item.userId)} data-bs-toggle="modal" data-bs-target="#viewApprovedCodeModal">
               <a className="dropdown-item border-radius-md" href="javascript:;">
                 <div className="d-flex py-1">
                     <h6 className="text-sm font-weight-normal mb-1">
                       <span className="font-weight-bold">Approve with code</span>
                     </h6>
                 </div>
               </a>
             </li>
           </ul>
           </td>
          </tr>
        );
      });
    } catch (e) {
      Swal.fire({
        title: "Session expired",
        text: "Login",
        type: "error",
      }).then(() => {
        window.location.href = "/login";
      });
    }
  };

  componentDidMount() {
    this.goPending();
    // this.showSelectItems()
  }

  // showSelectItems = async () => {
  //   fetch("https://rubimoni.com:8443/Lookups/GetLoanStatus", {
  //     method: "GET",
  //     headers: {
  //       Authorization: "Bearer " + localStorage.getItem("login"),
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       console.log(responseJson);
  //       //this.setState({select: responseJson})
  //     })
  //     .catch((error) => {
  //       // console.log(error)
  //     });
  // };

  render() {
    const { isLoading, isApprovedLoading, isApprovedWithCodeLoading, approvedUserData, userPendingData } = this.state;
    return (
      <div>
        <Header />
        <div
          className="container d-flex justify-content-end"
          style={{ marginBottom: 60, marginTop: 0, position: 'relative', left: -150 }}
        >
          {this.state.loadPending ? (
            <Spinner animation="border" variant="dark" />
          ) : (
            <div className="table-responsive" style={{ marginTop: 18 }}>
                <table
                  className="table align-items-center justify-content-center mb-0"
                >
                  <thead>
                  <tr>
                    <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">S/N</th>
                    <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Name</th>
                    <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Principal Amount</th>
                    <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Repayment Amount</th>
                    <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Loan Status</th>
                    <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Date Applied</th>
                    {/*<th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Date Approved</th>
                    <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Date Repaid</th> */}
                    <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 ps-2">Action</th>
                  </tr>
                  </thead>
                  <tbody>{this.showTablePending()}</tbody>
                </table>
            </div>
          )}
        </div>


        {/* Start of View Modal */}
        <div class="modal fade" id="viewPending" tabindex="-1" aria-labelledby="viewPending" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header d-flex align-items-center justify-content-between">
                <h5 class="modal-title">Details</h5>
                <button type="button" className="btn btn-link m-0 p-0 text-dark fs-4" data-bs-dismiss="modal" aria-label="Close"><span class="iconify" data-icon="carbon:close"></span></button>
              </div>
                { isLoading ? <Spinner animation="border" style={{ position:'relative', right:0, left: 220, top: 0 }} className="text-center" variant="danger" size="sm" />  :
              <div className="modal-body">
              { userPendingData.length > 0 && userPendingData.map((item) => {
                return (
                <div className="row">
                  <div className="d-flex px-3">
                    <div className="my-auto">
                      <img src={profileImage} className="avatar avatar-exbg  me-4 " />
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="text-lg font-weight-normal mb-1">
                        <span className="font-weight-bold">{`${item.surname} ${item.firstName} ${item.otherNames}`}</span>
                      </h6>
                        <span className="mb-2 text-sm font-weight-bold">USER ID: <span className="text-danger text-sm font-weight-bold">{item.userId}</span></span>
                        <span className="mb-2 text-sm font-weight-bold">APPLICATION ID: <span className="text-danger text-sm font-weight-bold">{item.applicationId}</span></span>
                      <span className="mb-2 text-sm font-weight-bold">Status: <span><button className={item.status === "approved" ? "btn btn-success badge badge-success text-xs font-weight-bold" : item.status === "pending" ? "btn btn-warning badge badge-warning text-xs font-weight-bold" : "btn btn-danger badge badge-danger text-xs font-weight-bold"}>{item.status}</button></span></span>
                    </div>
                  </div>
                  <span className="pt-1"><hr className="dark horizontal my-3" /></span>
                  <div className="d-flex flex-column px-3">
                    <h6 className="mb-3 text-sm">Loan History</h6>
                    <span className="mb-2 text-xs">Date Applied: <span className="text-dark font-weight-bold ms-sm-2">{item.dateApplied === "" ? null : moment(item.dateApplied).format("LL")}</span></span>
                    <span className="mb-2 text-xs">Date Approved: <span className="text-dark font-weight-bold ms-sm-2">{item.dateApproved === "" ? null : moment(item.dateApproved).format("LL")}</span></span>
                    <span className="mb-2 text-xs">Date Repaid: <span className="text-dark font-weight-bold ms-sm-2">{item.dateRepaid === "" ? null : moment(item.dateRepaid).format("LL")}</span></span>
                    <span className="mb-2 text-xs">Date Updated: <span className="text-dark font-weight-bold ms-sm-2">{item.dateupdated === "" ? null : moment(item.dateupdated).format("LL")}</span></span>
                    <span className="mb-2 text-xs">Interest Rate: <span className="text-success ms-sm-2 font-weight-bold">{item.interestRate}</span></span>
                    <span className="mb-2 text-xs">Loan Code: <span className="text-success ms-sm-2 font-weight-bold">{item.loancode}</span></span>
                    <span className="mb-2 text-xs">Purpose: <span className="text-dark ms-sm-2 font-weight-bold">{item.purpose}</span></span>
                    <span className="mb-2 text-xs">Principal Amount: <span className="text-dark ms-sm-2 font-weight-bold">  &#8358;
                      <NumberFormat
                      value={item.principalAmount}
                      displayType={'text'}
                      thousandSeparator={true}
                      renderText={value => <span> {value}</span>}
                      />
                      </span></span>
                    <span className="mb-2 text-xs">Repayment Amount: <span className="text-dark ms-sm-2 font-weight-bold">&#8358;
                      <NumberFormat
                      value={item.repaymentamount}
                      displayType={'text'}
                      thousandSeparator={true}
                      renderText={value => <span> {value}</span>}
                      />
                      </span></span>
                  </div>
                </div>
              )})}
              </div>
              }
              <div className="modal-footer">
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* End of View Modal */}


        {/* Start of Approved Modal  */}
        <div className="modal fade" id="viewApprovedModal" tabindex="-1" aria-labelledby="viewApprovedModal" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header d-flex align-items-center justify-content-between">
                <h5 className="modal-title">Approve Loan</h5>
                <button type="button" class="btn btn-link m-0 p-0 text-dark fs-4" data-bs-dismiss="modal" aria-label="Close"><span class="iconify" data-icon="carbon:close"></span></button>
              </div>
              { isLoading ? <Spinner animation="border" style={{ position:'relative', right:0, left: 220, top: 0, padding: 10 }} className="text-center" variant="danger" size="sm" />  :
              <div className="modal-body">
                <div className="row">
                  <div className="d-flex px-3">
                    <div className="my-auto">
                      <img src={logo} className="avatar avatar-exbg  me-4 " />
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="text-lg font-weight-normal mb-1">
                        <span className="font-weight-bold">Rubi Moni</span>
                      </h6>
                    </div>
                  </div>
                  <span className="pt-3"><hr class="dark horizontal my-3" /></span>
                  <div className="d-flex flex-column px-3">
                  { approvedUserData.length > 0 && approvedUserData.map((item) => {
                    return (
                     <p className="text-left font-weight-bold">{`Are you sure you want to approve`}<span className="text-success">{` ${item.surname} ${item.firstName} ${item.otherNames} with loan of `}
                    <br/> &#8358;
                     <NumberFormat
                       value={item.principalAmount}
                       displayType={'text'}
                       thousandSeparator={true}
                       renderText={value => <span> {value}</span>}
                       /> ?</span></p>
                       )})}
                  </div>
                </div>
              </div>
            }
              <div className="modal-footer">
                <button data-bs-dismiss="modal" type="button" className="btn btn-secondary">Deny</button>
                { approvedUserData.length > 0 && approvedUserData.map((item) => {
                  return (
                <button id = { item.applicationId } type="button" className="btn btn-success" onClick={() => this.generateLoanCode(item.applicationId)}>
                  {isApprovedLoading ?  <Spinner size="sm" animation="border" variant="light" /> : 'Approve'}
                </button>
                 )})}
              </div>
            </div>
          </div>
        </div>
        {/* End of Approved Modal */}

        {/* Start of Approve With Code Modal  */}
        <div className="modal fade" id="viewApprovedCodeModal" tabindex="-1" aria-labelledby="viewApprovedModal" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header d-flex align-items-center justify-content-between">
                <h5 className="modal-title">Approve Loan</h5>
                <button type="button" className="btn btn-link m-0 p-0 text-dark fs-4" data-bs-dismiss="modal" aria-label="Close"><span class="iconify" data-icon="carbon:close"></span></button>
              </div>
              { isLoading ? <Spinner animation="border" style={{ position:'relative', right:0, left: 220, top: 0, padding: 10 }} className="text-center" variant="danger" size="sm" />  :
              <div className="modal-body">
                <div className="row">
                  <div className="d-flex px-3">
                    <div className="my-auto">
                      <img src={logo} className="avatar avatar-exbg  me-4 " alt="rubi moni"/>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="text-lg font-weight-normal mb-1">
                        <span className="font-weight-bold">Rubi Moni</span>
                      </h6>
                    </div>
                  </div>
                  <span className="pt-3"><hr class="dark horizontal my-3" /></span>
                  <div className="d-flex flex-column px-3">
                  <div className="d-flex flex-row justify-content-between px-3">
                  <label className="form-label">LOAN CODE</label>
                  {/*<button onClick={() => this.goGenerateLoanCode()} className="btn btn-danger text-right font-weight-bold text-decoration-none">GENERATE LOAN CODE</button> */}
                  </div>
                  <div className="input-group input-group-outline mb-3">
                    <input type="text" className="form-control" onChange={e => this.setState({ loanCode: e.target.value })} />
                  </div>
                  </div>
                </div>
              </div>
            }
              <div className="modal-footer">
                <button data-bs-dismiss="modal" type="button" className="btn btn-secondary">Deny</button>
                { approvedUserData.length > 0 && approvedUserData.map((item) => {
                  return (
                <button id = { item.applicationId } type="button" className="btn btn-success" onClick={() => this.approveWithCode(item.applicationId)}>
                  {isApprovedWithCodeLoading ?  <Spinner size="sm" animation="border" variant="light" /> : 'Approve'}
                </button>
                 )})}
              </div>
            </div>
          </div>
        </div>
        {/* End of Approve  Modal */}


      </div>
    );
  }
}

export default Loans;
