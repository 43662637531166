import { Component } from 'react';
import { Link } from "react-router-dom";
import logo from '../assets/images/logo.png';
import Header from "../components/Header";
import { Spinner } from "react-bootstrap";
import { baseUrl } from "../components/BaseUrl";
import Swal from "sweetalert2";

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isActiveLoanLoading: false,
      isRepaidLoanLoading: false,
      isPendingLoanLoading: false,
      isSuspendingLoanLoading: false,
      isUsersLoading: false,
      activeLoans: [],
      repaidLoans: [],
      pendingLoans: [],
      suspendedLoans: [],
      users: []
    }
  }

  showActiveLoans = async() => {
    this.setState({ isActiveLoanLoading: true });
    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    };
    await fetch(`${baseUrl}LoansMgt/GetLoansByStatus/approved`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.warn(responseJson);
        try {
          this.setState({
            isActiveLoanLoading: false,
            activeLoans: responseJson,
          });
        } catch (error) {
          Swal.fire({
            title: "Session expired",
            text: "Login",
            type: "error",
          }).then(() => {
            this.props.history.push("/login")
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }

  showRepaidLoans = async() => {
    this.setState({ isRepaidLoanLoading: true });
    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    };
    await fetch(`${baseUrl}LoansMgt/GetLoansByStatus/repaid`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.warn(responseJson);
        try {
          this.setState({
            isRepaidLoanLoading: false,
            repaidLoans: responseJson,
          });
        } catch (error) {
          Swal.fire({
            title: "Session expired",
            text: "Login",
            type: "error",
          }).then(() => {
            this.props.history.push("/login")
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }

  showPendingLoans = async() => {
    this.setState({ isPendingLoanLoading: true });
    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    };
    await fetch(`${baseUrl}LoansMgt/GetLoansByStatus/pending`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.warn(responseJson);
        try {
          this.setState({
            isPendingLoanLoading: false,
            pendingLoans: responseJson,
          });
        } catch (error) {
          Swal.fire({
            title: "Session expired",
            text: "Login",
            type: "error",
          }).then(() => {
            this.props.history.push("/login")
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }

  showSuspendedLoans = async() => {
    this.setState({ isSuspendingLoanLoading: true });
    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    };
    await fetch(`${baseUrl}LoansMgt/GetLoansByStatus/suspended`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.warn(responseJson);
        try {
          this.setState({
            isSuspendingLoanLoading: false,
            suspendedLoans: responseJson,
          });
        } catch (error) {
          Swal.fire({
            title: "Session expired",
            text: "Login",
            type: "error",
          }).then(() => {
            this.props.history.push("/login")
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }

  showUsers = async() => {
    this.setState({ isUsersLoading: true });
    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    };
    await fetch(`${baseUrl}UsersMgt/GetUsersByType`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.warn(responseJson);
        try {
          this.setState({
            isUsersLoading: false,
            users: responseJson,
          });
        } catch (error) {
          Swal.fire({
            title: "Session expired",
            text: "Login",
            type: "error",
          }).then(() => {
            this.props.history.push("/login")
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }

  componentDidMount(){
    this.showActiveLoans();
    this.showRepaidLoans();
    this.showPendingLoans();
    this.showSuspendedLoans();
    this.showUsers();
  }


  render() {
    const { activeLoans, repaidLoans, pendingLoans, users, suspendedLoans, isSuspendingLoanLoading, isUsersLoading, isRepaidLoanLoading, isPendingLoanLoading, isActiveLoanLoading } = this.state;
    return (
      <div>
        <Header />
      <div>
    <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
      <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
        <div class="container-fluid py-1 px-3">
          <nav aria-label="breadcrumb">
            <h5 class="font-weight-bolder mb-0">Rubi Moni | Dashboard</h5>
          </nav>
        </div>
      </nav>

      <div class="container-fluid justify-content-center py-4" style={{ width: '70%', position: 'relative', left: 81 }}>
        <div class="row">
          <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
            <div class="card bg-success">
              <div class="card-header bg-success p-3 pt-2">
                <div class="icon icon-lg icon-shape bg-gradient-success shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                  <i class="material-icons opacity-10">store</i>
                </div>
                <div class="text-end pt-1">
                  <p class="text-sm mb-0 text-light text-capitalize fw-bold">Active Loans</p>
                </div>
              </div>
              <span class="pt-4"><hr class="light text-light horizontal my-0" /></span>
              <div class="card-footer p-3">
              {isActiveLoanLoading ? <Spinner animation="border" className="justify-content-center" variant="light" /> :
                activeLoans.length > 0 &&
                <h4 class="mb-0 text-light font-weight-bold text-end">{activeLoans.length}</h4>
              }
              </div>

            </div>
          </div>
          <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
            <div class="card bg-warning">
              <div class="card-header bg-gradient-danger p-3 pt-2">
                <div class="icon icon-lg icon-shape bg-gradient-warning shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                  <i class="material-icons opacity-10">receipt</i>
                </div>
                <div class="text-end pt-1">
                  <p class="text-sm mb-0 text-light text-capitalize fw-bold">Pending Loans</p>
                </div>
              </div>
              <span class="pt-4"><hr class="dark horizontal my-0" /></span>
              <div class="card-footer p-3">
              {isPendingLoanLoading ? <Spinner animation="border" className="justify-content-center" variant="light" /> :
               pendingLoans.length > 0 &&
                  <h4 class="mb-0 text-light fw-bold text-end">{ pendingLoans.length == 0 ? "Empty" : pendingLoans.length }</h4>
              }
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
            <div class="card bg-success">
              <div class="card-header bg-success p-3 pt-2">
                <div class="icon icon-lg icon-shape bg-gradient-warning shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                  <i class="material-icons opacity-10">badge</i>
                </div>
                <div class="text-end pt-1">
                  <p class="text-sm mb-0 text-light text-capitalize fw-bold">Repaid Loans</p>
                </div>
              </div>
              <span class="pt-4"><hr class="dark horizontal my-0" /></span>
              <div class="card-footer p-3">
              {isRepaidLoanLoading ? <Spinner animation="border" className="justify-content-center" variant="light" /> :
               repaidLoans.length > 0 &&
                  <h4 class="mb-0 text-light fw-bold text-end">{ repaidLoans.length }</h4>
              }
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-4 col-md-6 mt-4 mb-4">
            <div class="card bg-gradient-warning z-index-2 ">
              <div class="card-body">
                <h6 class="mb-0 text-light font-weight-bold text-end">Suspended Loans</h6>
                <hr class="dark horizontal" />
                {/* <p class="text-sm text-end">Total Number of Sort Codes Available</p> */}
                {isSuspendingLoanLoading ? <Spinner animation="border" className="justify-content-center" variant="light" /> :
                // suspendedLoans.length > 0 &&
                    <h4 class="mb-0 text-light fw-bold text-end">{ suspendedLoans.length <= 0 ? 0  : suspendedLoans.length }</h4>
                }
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 mt-4 mb-4">
            <div class="card bg-success z-index-2  ">
              <div class="card-body">
                <h6 class="mb-0 text-light font-weight-bold text-end"> Users </h6>
                <hr class="dark horizontal" />
                {/* <p class="text-sm text-end">Total Number Of Invoice Available</p> */}
                {isUsersLoading ? <Spinner animation="border" className="justify-content-center" variant="light" /> :
                 users.length > 0 &&
                    <h4 class="mb-0 text-light fw-bold text-end">{ users.length }</h4>
                }
              </div>
            </div>
          </div>
          <div class="col-lg-4 mt-4 mb-3">
            <div class="card bg-gradient-danger z-index-2 ">
              <div class="card-body">
                <h6 class="mb-0 text-light font-weight-bold text-end">Declined Applications</h6>
                <hr class="dark horizontal" />
                {/* <p class="text-sm text-end">Total Standard Numbers Available</p> */}
                <h4 class="mb-0 text-light fw-bold text-end">5</h4>
              </div>
            </div>
          </div>
        </div>
        </div>
      </main>
       </div>
       </div>
    );
  }
}

export default Dashboard;
