import React from 'react';
import { BrowserRouter, Route, HashRouter, Switch, Redirect } from "react-router-dom";
// import Auth from './screens/Auth';
import Login from './screens/Login';
import Dashboard from './screens/Dashboard';
import Register from './screens/Register';
import Loans from './screens/PendingLoans';
import Users from './screens/Users';
import LoanAdmin from './screens/LoanAdmin';
import SearchOffersById from './screens/SearchOffersById';
import SearchUserByOffer from './screens/SearchUserByOffer';
import EditOffers from './screens/EditOffers';
import PublicUsers from './screens/PublicUsers';
import PrivateUsers from './screens/PrivateUsers';
import SuspendedLonas from './screens/SuspendedLoans';
import PendingLoans from './screens/PendingLoans';
import RepaidLoans from './screens/RepaidLoans';
import ApprovedLoans from './screens/ApprovedLoans';
import RunningLoans from './screens/RunningLoans';
import UpdateLedger from './screens/UpdateLedger';
import GenerateLoanCode from './screens/GenerateLoanCode';
import CreateOffer from './screens/CreateOffer';
import Logout from './screens/Logout';

function App() {
  return (
    <HashRouter>
      <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register"  component={Register} />
      <Route path="/dashboard"  component={Dashboard} />
      <Route path="/loans"  component={Loans} />
      <Route path="/suspended-loans"  component={SuspendedLonas} />
      <Route path="/pending-loans"  component={PendingLoans} />
      <Route path="/repaid-loans"  component={RepaidLoans} />
      <Route path="/approved-loans"  component={ApprovedLoans} />
      <Route path="/active-loans"  component={RunningLoans} />
      <Route path="/loan-admin"  component={LoanAdmin} />
      <Route path="/users"  component={Users} render= {(props)=>window.location.reload()} />
      <Route path="/public-users"  component={PublicUsers} />
      <Route path="/private-users"  component={PrivateUsers} />
      <Route path="/search-offers-by-id"  component={SearchOffersById} />
      <Route path="/search-users-by-offers"  component={SearchUserByOffer} />
      <Route path="/edit-offers"  component={EditOffers} />
      <Route path="/create-offer"  component={CreateOffer} />
      <Route path="/generate-loan-code"  component={GenerateLoanCode} />
      <Route path="/update-ledger"  component={UpdateLedger} />
      <Route path="/logout"  component={Logout} />
      <Redirect from="/" to="/login" />
      {/* <Route exact path="/" component={Login} /> */}
      {/* <Redirect from="/" to="/login" /> */}
      {/* <Redirect from="/" to="/auth" /> */}
      </Switch>
      </HashRouter>
  );
}

export default App;
