import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import Header from "../components/Header";

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount(){
       localStorage.removeItem("login");
        this.props.history.push("/login")
  }

  render() {
    return (
      <div>
        <Header />
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 350,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            {this.state.loading ? (
              <Spinner animation="border" variant="dark" />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Logout;
