import React, { Component } from "react";
import { Container, Spinner, Row, Col, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../components/Header";
import OfferOptions from "../components/OfferOptions";
import Footer from "../components/Footer";
import $ from "jquery";
import Swal from "sweetalert2";

class SearchOffersById extends Component {
  state = {
    query: "",
    results: [],
    showResults: false,
  };

  changeText(event) {
    this.setState({
      query: event.target.value,
    });
  }

  hideLoader() {
    this.setState({ showResults: false });
  }

  getInfo = async () => {
    this.setState({ showResults: true });
    if (this.state.query === "") {
      Swal.fire({
        title: "Error!",
        text: "Enter a search value",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.hideLoader();
    } else if (this.state.query.length > 1) {
      this.hideLoader();
      Swal.fire({
        title: "Error!",
        text: "Enter a valid search value",
        icon: "error",
        confirmButtonText: "OK",
      });
    } else {
      let obj = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("login"),
        },
      };
      await fetch(
        "https://rubimoni.com:8443/Offers/stage/" + this.state.query,
        obj
      )
        .then((response) => response.json())
        .then((responseJson) => {
          try {
            this.setState({
              results: responseJson,
              showResults: false,
            });
          } catch (error) {
            this.hideLoader();
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          this.hideLoader();
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  showSearch = () => {
    $(document).ready(function() {
      $("#approved").DataTable();
    });
    return this.state.results.map((item) => {
      return (
        <tr>
          <td>#</td>
          <td>{item.LoanId}</td>
          <td>{item.stageId}</td>
          <td>{item.stageName}</td>
          <td>{item.interest}</td>
          <td>{item.principal}</td>
          <td>{item.penalty}</td>
          <td>{item.Tenure}</td>
        </tr>
      );
    });
  };

  render() {
    return (
      <div>
        <Header />
        <Container>
          <OfferOptions />
          <div className="searchDisplay">
            <Form>
              <Row>
                <Col>
                  <Form.Control
                    size="lg"
                    placeholder="Enter offer id"
                    onChange={this.changeText.bind(this)}
                  />
                </Col>
                <Col>
                  <Button variant={"primary"} onClick={this.getInfo} size="lg">
                    {this.state.showResults ? (
                      <Spinner animation="border" size="sm" variant="light" />
                    ) : (
                      <span>search</span>
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>

          {this.state.showResults ? null : (
            <div className="container" style={{ marginTop: 18 }}>
              <div>
                <table
                  id="approved"
                  className="table display"
                  cellSpacing="0"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Loan ID</th>
                      <th>Stage ID</th>
                      <th>Stage Name</th>
                      <th>Interest</th>
                      <th>Principal</th>
                      <th>Penalty</th>
                      <th>Tenure</th>
                    </tr>
                  </thead>
                  <tbody>{this.showSearch()}</tbody>
                </table>
              </div>
            </div>
          )}
        </Container>
        <Footer />
      </div>
    );
  }
}

export default SearchOffersById;
