import React, { Component } from "react";
import { Spinner, Table, Row, Col } from "react-bootstrap";
import { baseUrl } from "../components/BaseUrl";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import Swal from "sweetalert2";
import Footer from "../components/Footer";
import Header from "../components/Header";
import UserOptions from "../components/UserOptions";

class PrivateUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadPrivate: false,
      myData: [],
      userId: "",
      selectValue: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onInputchange = this.onInputchange.bind(this);
  }

  checkLocalStorage() {
    if (localStorage.getItem("login") === "") {
      window.location.href = "/login";
    }
  }

  onInputchange(e) {
    this.setState({ userId: e.target.value });
  }

  handleChange(e) {
    this.setState({ selectValue: e.target.value });
  }

  async handleSubmit(e) {
    if (this.state.selectValue === "" || this.state.selectValue === "Choose") {
      alert("You have not made any selection. Choose enable or disable");
      e.preventDefault();
    } else {
      if (this.state.selectValue.startsWith("diable")) {
        Swal.fire({
          title: "One moment ...",
          text: "Getting things ready",
        });
        let str = this.state.selectValue.substring(6);
        e.preventDefault();
        var obj = {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("login"),
          },
          body: JSON.stringify({
            status: "disabled",
            userid: str.trim(),
          }),
        };
        await fetch(`${baseUrl}UsersMgt/UpdateUserStatus`, obj)
          .then((response) => response.json())
          .then((responseJson) => {
            Swal.close();
            if (window.location.reload()) {
              Swal.fire({
                title: "Success",
                text: str.trim() + " has been successfully disabled",
                icon: "success",
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      } else if (this.state.selectValue.startsWith("enable")) {
        Swal.fire({
          title: "One moment ...",
          text: "Getting things ready",
        });
        let str = this.state.selectValue.substring(6);
        e.preventDefault();
        let obj = {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("login"),
          },
          body: JSON.stringify({
            status: "enabled",
            userid: str.trim(),
          }),
        };
        await fetch(`${baseUrl}UsersMgt/UpdateUserStatus/public`, obj)
          .then((response) => response.json())
          .then((responseJson) => {
            if (window.location.reload()) {
              Swal.fire({
                title: "Success",
                text: str.trim() + " has been successfully enabled.",
                icon: "success",
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      }
    }
  }

  goPrivate = async () => {
    this.setState({ loadPrivate: true });
    if (!$.fn.DataTable.isDataTable("#myTable")) {
      $(document).ready(function() {
        setTimeout(function() {
          $("#myTable").DataTable({
            pagingType: "full_numbers",
            pageLength: 20,
            processing: true,
            dom: "Bfrtip",
            select: {
              style: "single",
            },

            buttons: [
              {
                extend: "pageLength",
                className: "btn btn-secondary bg-secondary",
              },
              {
                extend: "copy",
                className: "btn btn-secondary bg-secondary",
              },
              {
                extend: "csv",
                className: "btn btn-secondary bg-secondary",
              },
              {
                extend: "print",
                customize: function(win) {
                  $(win.document.body).css("font-size", "10pt");
                  $(win.document.body)
                    .find("table")
                    .addClass("compact")
                    .css("font-size", "inherit");
                },
                className: "btn btn-secondary bg-secondary",
              },
            ],

            fnRowCallback: function(
              nRow,
              aData,
              iDisplayIndex,
              iDisplayIndexFull
            ) {
              var index = iDisplayIndexFull + 1;
              $("td:first", nRow).html(index);
              return nRow;
            },

            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function(data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });
        }, 1000);
      });
    }
    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("login"),
      },
    };
    await fetch(`${baseUrl}UsersMgt/GetUsersByType/private`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        try {
          this.setState({
            loadPrivate: false,
            myData: responseJson,
          });
        } catch (e) {
          alert(e);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  showTable = () => {
    try {
      return this.state.myData.map((item) => {
        return (
          <tr style={{ wordBreak: "break-all" }}>
            <td>#</td>
            <td>
              <image src={item.image} />
            </td>
            <td>{item.userId}</td>
            <td>{item.stageId}</td>
            <td>{item.usertype}</td>
            <td>{item.active}</td>
            <td>{item.surname}</td>
            <td>{item.firstName}</td>
            <td>{item.otherNames}</td>
            <td>{item.gender}</td>
            <td>{item.countryId}</td>
            <td>{item.address}</td>
            <td>{item.city}</td>
            <td>{item.state}</td>
            <td>{item.education}</td>
            <td>{item.DOB}</td>
            <td>{item.telephone}</td>
            <td>{item.telephoneVerified}</td>
            <td>{item.email}</td>
            <td>{item.emailVerified}</td>
            <td>{item.status}</td>
            <td style={{ width: 200 }}>
              <Col>
                <Row>
                  <form
                    className="form form-control"
                    onSubmit={this.handleSubmit}
                  >
                    <select
                      className="form-control"
                      onChange={this.handleChange}
                    >
                      <option value="Choose">Choose</option>
                      <option value={"diable " + item.userId}>Disable</option>
                      <option value={"enable " + item.userId}>Enable</option>
                    </select>
                    <button className="btn form-control btn-primary btn-block">
                      Go
                    </button>
                  </form>
                </Row>
              </Col>
            </td>
          </tr>
        );
      });
    } catch (e) {
      Swal.fire({
        title: "Session expired",
        text: "Login",
        type: "error",
      }).then(function() {
        window.location.href = "/login";
      });
    }
  };

  componentDidMount() {
    this.goPrivate();
    this.checkLocalStorage();
  }

  render() {
    return (
      <div>
        <Header />
        <UserOptions />
        <div className="container d-flex justify-content-center" style={{marginTop: 45, marginBottom: 80}}>
          {this.state.loadPrivate ? (
            <Spinner animation="border" variant="dark" />
          ) : (
            <div className="container table-responsive" style={{ marginTop: 18 }}>
              <div className="container-fluid">
                <Table
                  id="myTable"
                  style={{ tableLayout: "fixed", width: "180%" }}
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Image</th>
                      <th>User Id</th>
                      <th>Stage Id</th>
                      <th>User type</th>
                      <th>Active</th>
                      <th>Surname</th>
                      <th>First name</th>
                      <th>Other names</th>
                      <th>Gender</th>
                      <th>Country Id</th>
                      <th>Address</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Education</th>
                      <th>DOB</th>
                      <th>Telephone</th>
                      <th>Telephone verified</th>
                      <th>Email</th>
                      <th>Email verified</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.showTable()}</tbody>
                </Table>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    );
  }
}

export default PrivateUsers;
