import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';



class LoanOptions extends Component {
    render(){
        return(
            <div className="searchContainer">
        <Link to="/loans">
          <Button variant={"primary"} size="lg">
            Pending Loans
          </Button>
        </Link>

        <Link to="/approved-loans">
          <Button variant={"primary"} size="lg">
            Approved Loans
          </Button>
        </Link>
        <Link to="/running-loans">
          <Button variant={"primary"} size="lg">
            Running Loans
          </Button>
        </Link>
        <Link to="/suspended-loans">
          <Button variant={"primary"} size="lg">
            Suspended Loans
          </Button>
        </Link>
        <Link to="/repaid-loans">
          <Button variant={"primary"} size="lg">
            Repaid Loans
          </Button>
        </Link>
      </div>
        )
    }
}

export default LoanOptions;