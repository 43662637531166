import React, { Component } from 'react';
import Header from '../components/Header';
import axios from 'axios';
import { baseUrl } from '../components/BaseUrl';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';


class GenerateLoanCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applicationId: "",
      loanCode: "",
      isLoading: false,
    }
  }

  generateLoanCode = async () => {
    const { applicationId } = this.state;
   this.setState({ isLoading: true });
   if (localStorage.getItem("login") === "") {
     this.props.history.push("/login")
   }
   if(applicationId === ''){
     this.setState({isLoading: false})
     Swal.fire({
       title: 'Empty',
       text: 'Please enter the APPLICATION ID',
       icon: 'error',
       confirmButtonText: 'OK'
     })
   }else{
     var obj = {
       method: "PUT",
       headers: {
         Accept: "application/json",
         "Content-Type": "application/json",
         Authorization: "Bearer " + localStorage.getItem("login"),
       },
       body: JSON.stringify({
         applicationId: `${applicationId}`,
       }),
     };
     await fetch(`${baseUrl}Loans/generateCode`, obj)
       .then((response) => response.json())
       .then((responseJson) => {
         // console.warn(responseJson);
         if (responseJson.message === "Loan Activated") {
           this.setState({ isLoading: false, loanCode: responseJson.loancode })
           Swal.fire({
             title: "Loan Code Generated Successfully",
             text: `Loan code is ${responseJson.loancode}`,
             icon: "success",
             confirmButtonText: "OK",
           });
         } else {
           this.setState({ isLoading: false});
           Swal.fire({
             title: "Something went wrong",
             text: responseJson.message,
             icon: "error",
             confirmButtonText: "OK",
           });
         }
       })
       .catch((error) => {
         Swal.fire({
           title: "Error!",
           text: error.message,
           icon: "error",
           confirmButtonText: "OK",
         });
       });
       this.setState({ isLoading: false });
 }
}


  render() {
    const { isLoading, loanCode } = this.state;
    return (
      <div>
      <Header />

      <div className="container d-flex justify-content-center">
      {loanCode &&
      <div className="alert alert-success text-light font-weight-bold d-flex justify-content-center col-lg-6 col-md-6 col-sm-12">
        {loanCode}
      </div>
    }
    </div>
      <div role="form" className="d-flex justify-content-center">
                <hr />

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label className="form-label">APPLICATION ID</label>
                    <div className="input-group input-group-outline mb-3">
                      <input type="text" class="form-control" onChange={e => this.setState({ applicationId: e.target.value })} />
                    </div>
                  </div>
                    <div className="col">
                    <div><label className="form-label opacity-0">btn label</label></div>
                    <button type="button" onClick={e => this.generateLoanCode(e)} class="btn btn-success bg-success btn-lg form-control">
                    {isLoading ?  <Spinner animation="border" variant="light" /> : 'Generate Loan Code'}
                    </button>
                  </div>
                </div>
                <hr />
              </div>
      </div>


    );
  }
}

export default GenerateLoanCode
