import React, { Component } from "react";
import {Link} from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import { baseUrl } from '../components/BaseUrl';
import { Spinner } from 'react-bootstrap';
import logo from '../images/logo1.jpg';


class Login extends Component {

       state = {
         username: '',
         password: '',
         loading: false
        }

    handleFormSubmit = (event ) => {
        event.preventDefault();
         this.setState({loading: true})
        if(this.state.username === '' && this.state.password === ''){
          this.setState({loading: false})
          Swal.fire({
            title: 'Empty',
            text: 'Complete the form',
            icon: 'error',
            confirmButtonText: 'OK'
          }.bind(this))
        }else{
        this.setState({loading: true})
        axios.post(`${baseUrl}Admin/login`,
        {
          email: this.state.username,
          password: this.state.password,
        }).then(function (response) {
          console.warn(response);
          if(response.status === 200) {
            this.setState({loading: false})
            localStorage.setItem('login', response.data.Token);
            this.props.history.push("/dashboard")
          }
       }.bind(this))
       .catch(function (err) {
         this.setState({loading: false})
           Swal.fire({
               title: 'Error!',
               text: err.message,
               icon: 'error',
               confirmButtonText: 'OK'
             })
       }.bind(this));
    }
    }

    render(){
      const {loading} = this.state
        return(
          <div className="login-container">
          <div className="logo-item">
          <img src={logo} style={{ width: '5%'}} alt="Rubi Moni"/>
          </div>
            <div className="form-background">
            <div className="threed">
            <div className="con">
            <div className="header">Ruby Moni | Login</div>
            <div className="wrap">
            <div className="formColumn">


            <input type="text" placeholder="email or phone"
             onChange={e => this.setState({ username: e.target.value })} />

            <input type="password" id="password" placeholder="Password"
             onChange={e => this.setState({ password: e.target.value })} />

            <div className="group">
            <button onClick={e => this.handleFormSubmit(e)}>
            {loading ?  <Spinner animation="border" variant="light" /> : 'Login'}
            </button>
            </div>
            </div>
            </div>
        </div>
      </div>
     </div>
     </div>
        )
    }
}

export default Login;
